import parameterAPI from "../plugins/api/monitor/paremeter";

export default {
  async getParameter(customerId) {
    const { data: rules } = await parameterAPI.getRules(customerId);
    const { data: conditions } = await parameterAPI.getCondition(customerId);
    const { data: levels } = await parameterAPI.getLevels(customerId);
    return { rules, conditions, levels };
  }
};
