<template>
  <div>
    <div class="row mx-4 my-3">
      <div class="col-4">
        <label for="placement" class="text-white">PLACEMENT</label>
        <input
          name="placement"
          type="text"
          class="form-control"
          style="height: 30px"
          v-model="filter.placement"
        />
      </div>
      <div
        class="col-auto dropdown pl-0 pt-3 mt-1"
        v-bind:class="{ show: show }"
        v-on:click="changeStatus()"
      >
        <a
          v-bind:class="{ isSelectedAnyCheckbox }"
          class="
            btn
            button
            dropdown-toggle
            text-white
            d-flex
            justify-content-center
            align-items-center
            font
          "
          role="button"
          id="dropdownMenuLink button"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-funnel mr-2"
            viewBox="0 0 16 16"
          >
            <path
              d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"
            />
          </svg>
          FILTRAR
        </a>
        <div
          class="dropdown-menu px-2"
          style="background-color: #323232"
          aria-labelledby="dropdownMenuLink"
        >
          <base-checkbox class="mb-3 dynad-flex-1" v-model="checkbox.campaign"
          
            >Campanha</base-checkbox
          >
          <base-checkbox
            class="mb-3 dynad-flex-1"
            v-model="checkbox.creationDate"
            v-if="tab !== 'Resolvidos'"
            >Data de Criação</base-checkbox
          >
          <base-checkbox
            class="mb-3 dynad-flex-1"
            v-model="checkbox.endDate"
            v-if="tab !== 'Resolvidos'"
            >Data fim prevista da campanha</base-checkbox
          >
          <base-checkbox
            class="mb-3 dynad-flex-1"
            v-model="checkbox.appointmentIndicator"
            >Indicador do apontamento</base-checkbox
          >
          <base-checkbox
            class="mb-3 dynad-flex-1"
            v-model="checkbox.appearanceDate"
            >Data de Surgimento</base-checkbox
          >
          <base-checkbox class="mb-3 dynad-flex-1" v-model="checkbox.level"
            >Grau</base-checkbox
          >
          <base-button type="primary" class="dynad-btn-report">
            Selecionar
          </base-button>
        </div>
      </div>
    </div>
    <div>
      <div class="row w-100 ml-0 px-4 filter-dynad">
        <div class="col-4 mt-2 flex-wrap" v-show="checkbox.campaign">
          <label class="text-white">Campanha</label>
          <input
            name="campaign_name"
            type="text"
            class="form-control"
            style="height: 30px"
            v-model="filter.campaign_name"
          />
        </div>
        <div
          class="col-4 mt-2 flex-wrap"
          v-show="checkbox.appointmentIndicator"
        >
          <label class="text-white">Indicador</label>
          <input
            name="monitor_rule"
            type="text"
            class="form-control"
            style="height: 30px"
            v-model="filter.monitor_rule"
          />
        </div>
        <div class="col-3 mt-2 flex-wrap" v-show="checkbox.level">
          <label class="text-white">Grau </label>
          <vSelect
            name="monitor_level"
            class="style-chooser"
            :options="levels"
            v-model="filter.monitor_level"
          />
        </div>
      </div>
      <div class="row w-100 ml-0 px-4 filter-dynad">
        <div
          class="col-10 col-sm-5 col-md-3 col-lg-3 mt-2 ml-3"
          v-show="checkbox.creationDate"
        >
          <div class="row">
            <label class="text-white mb-2">Data de Criação</label>
          </div>
          <div class="row">
            <date-range-picker
              name="campaign_start_date"
              :showDropdowns="true"
              :linked-calendars="false"
              :locale-data="localeData"
              opens="right"
              id="dataRange"
              v-model="filter.dateRangeCreationDate"
            >
            </date-range-picker>
          </div>
        </div>
        <div
          class="col-10 col-sm-5 col-md-3 col-lg-3 mt-2 ml-3"
          v-show="checkbox.endDate"
        >
          <div class="row">
            <label class="text-white mb-2">Data Fim Prevista</label>
          </div>
          <div class="row">
            <date-range-picker
              :showDropdowns="true"
              :linked-calendars="false"
              :locale-data="localeData"
              id="dataRange"
              v-model="filter.dateRangeEndDate"
            ></date-range-picker>
          </div>
        </div>
        <div
          class="col-10 col-sm-5 col-md-3 col-lg-3 mt-2 ml-3"
          v-show="checkbox.appearanceDate"
        >
          <div class="row">
            <label class="text-white mb-2">Data Surgimento</label>
          </div>
          <div class="row">
            <date-range-picker
              :showDropdowns="true"
              :linked-calendars="false"
              :locale-data="localeData"
              id="dataRange"
              v-model="filter.dateRangeAppearanceDate"
            ></date-range-picker>
          </div>
        </div>
      </div>
      <div class="row filter-dynad mx-0 py-3" v-show="isSelectedAnyCheckbox">
        <div class="col-1.5">
          <a class="button-close" @click="clearFilters()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
              />
              <path
                fill-rule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
              />
            </svg>

            LIMPAR FILTROS
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import dateUtils from "../../Utils/date-util";

const DEFAULT_FILTERS = {
  dateRangeCreationDate: {},
  dateRangeEndDate: {},
  dateRangeAppearanceDate: {},
};

export default {
  components: { DateRangePicker, vSelect },
  props: ["handleFilter", "tab"],
  data() {
    return {
      show: false,
      filter: { ...DEFAULT_FILTERS },
      checkbox: {
        campaign: false,
        creationDate: false,
        endDate: false,
        appointmentIndicator: false,
        appearanceDate: false,
        level: false,
      },
      localeData: {
        direction: "ltr",
        format: "yyyy/mm/dd HH:mm:ss",
        separator: " - ",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Maio",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 1,
      },

      levels: ["Leve", "Importante", "Grave"],
    };
  },
  computed: {
    isSelectedAnyCheckbox() {
      for (const key in this.checkbox) {
        if (this.checkbox[key]) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.handleFilter(this.deleteProperty(this.filter));
      },
    },
    "filter.dateRangeCreationDate": {
      deep: true,
      handler(dateRangeCreationDate) {
        const startDate = dateUtils.formatYearMonthDayTime(
          dateRangeCreationDate.startDate,
          "YYYY/MM/DD HH:mm:ss"
        );
        const endDate = dateUtils.formatYearMonthDayTime(
          dateRangeCreationDate.endDate,
          "YYYY/MM/DD HH:mm:ss"
        );
        this.handleFilter({
          ...this.deleteProperty(this.filter),
          dateRangeCreationDate: { startDate, endDate },
        });
      },
    },
    "filter.dateRangeEndDate": {
      deep: true,
      handler(dateRangeEndDate) {
        const startDate = dateUtils.formatYearMonthDayTime(
          dateRangeEndDate.startDate,
          "YYYY/MM/DD HH:mm:ss"
        );
        const endDate = dateUtils.formatYearMonthDayTime(
          dateRangeEndDate.endDate,
          "YYYY/MM/DD HH:mm:ss"
        );
        this.handleFilter({
          ...this.deleteProperty(this.filter),
          dateRangeEndDate: { startDate, endDate },
        });
      },
    },
    "filter.dateRangeAppearanceDate": {
      deep: true,
      handler(dateRangeAppearanceDate) {
        const startDate = dateUtils.formatYearMonthDayTime(
          dateRangeAppearanceDate.startDate,
          "YYYY/MM/DD HH:mm:ss"
        );
        const endDate = dateUtils.formatYearMonthDayTime(
          dateRangeAppearanceDate.endDate,
          "YYYY/MM/DD HH:mm:ss"
        );
        this.handleFilter({
          ...this.deleteProperty(this.filter),
          dateRangeAppearanceDate: { startDate, endDate },
        });
      },
    },
  },
  methods: {
    formatDate() {
      let date = dateUtils.formatYearMonthDayTime(
        new Date(),
        "YYYY/MM/DD HH:mm:ss"
      );
      return date;
    },
    deleteProperty(object) {
      for (const key in object) {
        if (!object[key]) {
          delete object[key];
        }
      }
      return object;
    },
    clearFilters() {
      this.checkbox.campaign = false;
      this.checkbox.creationDate = false;
      this.checkbox.endDate = false;
      this.checkbox.appointmentIndicator = false;
      this.checkbox.appearanceDate = false;
      this.checkbox.level = false;
      this.filter = { ...DEFAULT_FILTERS };
    },
    changeStatus(status) {
      if (status !== undefined) this.show = Boolean(status);
      else this.show = !this.show;
    },
  },
};
</script>
<style>
.isSelectedAnyCheckbox {
  background: #FFFFFF66 !important;
}
.button-close {
  margin-left: 35px !important;
  cursor: pointer;
  color: var(--dynad) !important;
  font-size: 13px;
}
.button-close:hover {
  margin-left: 35px !important;
  cursor: pointer;
  color: white !important;
  font-size: 13px;
}
.filter-dynad {
  background: #00000033;
}
.font {
  height: 30px;
  font-size: 12px;
}
.button {
  background: #323232;
}
.button:hover,
.button::after {
  background: #FFFFFF66 !important;
}

</style>