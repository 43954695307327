<template>
  <div>
    <div v-show="errorMessage" class="loader">
      <h1 class="text-light">{{ errorMessage }}</h1>
    </div>
    <div v-show="!errorMessage">
      <div class="col-12 text-right">
        <base-button type="primary" class="dynad-btn-report" @click="goToForm">Novo Report</base-button>
      </div>
      <h4 class="p-0 mt-3">Reports Agendados:</h4>
      <div class="row">
        <base-table :data="scheduledTable" :columns="scheduledTableColumns">
          <template slot="columns">
            <th>Nome</th>
            <th>Dia do Agendamento</th>
            <th>Hora do Agendamento</th>
            <th>Agrupamento</th>
            <th>Tipo</th>
            <th>Status</th>
            <th>Ações</th>
            <th>Usuário de Atualização</th>
            <th>Última Atualização</th>
            <th>Usuário de Criação</th>
            <th>Data de Criação</th>
          </template>
          <template slot-scope="{ row }">
            <td>{{ row.report_name }}</td>
            <td>{{ row.schedule_day }}</td>
            <td>{{ row.schedule_hour }}</td>
            <td>{{ row.report_grouping }}</td>
            <td>{{ row.report_type }}</td>
            <td class="td-actions">
              <i
                v-if="row.status"
                class="tim-icons icon-check-2 dynad-icon-custom-reports"
              ></i>
              <i
                v-if="!row.status"
                class="tim-icons icon-button-pause dynad-icon-custom-reports"
              ></i>
            </td>
            <td class="td-actions">
              <base-button
                class="dynad-btn-custom-icons"
                size="sm"
                icon
                type="primary"
                title="Editar Report Agendado"
                @click="goToEditForm(row.id, 'scheduled')">
                <i class="tim-icons icon-pencil"></i>
              </base-button>
              <base-button
                class="dynad-btn-custom-icons"
                size="sm"
                icon
                type="primary"
                title="Excluir Report Agendado"
                @click="deleteCustomScheduledReport(row.id, row.report_name)">
                <i class="tim-icons icon-trash-simple"></i>
              </base-button>
            </td>
            <td>{{ row.user_updated }}</td>
            <td>{{ row.last_updated }}</td>
            <td>{{ row.user_created }}</td>
            <td>{{ row.date_created }}</td>
          </template>
        </base-table>
      </div>
      <h4 class="p-0 mt-3">Reports Por Data:</h4>
      <div class="mt-2 row">
        <base-table :data="byDateTable" :columns="byDateTableColumns">
          <template slot="columns">
            <th>Nome</th>
            <th>De</th>
            <th>Até</th>
            <th>Agrupamento</th>
            <th>Tipo</th>
            <th>Ações</th>
            <th>Usuário de Atualização</th>
            <th>Última Atualização</th>
            <th>Usuário de Criação</th>
            <th>Data de Criação</th>
          </template>
          <template slot-scope="{ row }">
            <td>{{ row.report_name }}</td>
            <td>{{ row.start_date }}</td>
            <td>{{ row.end_date }}</td>
            <td>{{ row.report_grouping }}</td>
            <td>{{ row.report_type }}</td>
            <td class="td-actions">
              <base-button
                v-if="row.status === 'FINISHED'"
                class="dynad-btn-custom-icons"
                size="sm"
                icon
                type="primary"
                title="Download do Report"
                @click="download_or_create_report(row)">
                <i class="tim-icons icon-cloud-download-93"></i>
              </base-button>
              <div v-if="row.status === 'PENDING'">
                <ScaleLoader
                  title="Processando..."
                  :loading="true"
                  color="#d6611f"
                  height="20px"
                  width="2px"
                  class="text-left"
                />
              </div>
              <div v-if="row.status === 'ERROR'">
                <i class="tim-icons icon-alert-circle-exc"></i>
              </div>
              <base-button
                v-if="row.status === 'FINISHED'"
                class="dynad-btn-custom-icons"
                size="sm"
                icon
                type="primary"
                title="Editar Report Por Data"
                @click="goToEditForm(row.id, 'byDate')">
                <i class="tim-icons icon-pencil"></i>
              </base-button>
              <base-button
                v-if="row.status === 'FINISHED'"
                class="dynad-btn-custom-icons"
                size="sm"
                icon
                type="primary"
                title="Excluir Report Por Data"
                @click="deleteCustomDateReport(row.id, row.report_name)">
                <i class="tim-icons icon-trash-simple"></i>
              </base-button>
            </td>
            <td>{{ row.user_updated }}</td>
            <td>{{ row.last_updated }}</td>
            <td>{{ row.user_created }}</td>
            <td>{{ row.date_created }}</td>
          </template>
        </base-table>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseTable } from "@/components";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import moment from "moment";

const TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export default {
  components: {
    BaseTable,
    ScaleLoader,
  },
  methods: {
    goToForm() {
      location.href = "/#/custom-report-form";
    },
    goToEditForm(id, type) {
      location.href = `/#/custom-report-form?id=${id}&type=${type}`
    },
    async download_or_create_report(row) {
      let lastUpdatedDateTime = moment(row.last_updated, TIME_FORMAT);
      let expirationDateTime = moment(row.last_updated, TIME_FORMAT).add(1, "days");
      let now = moment();
      if (now.isBetween(lastUpdatedDateTime, expirationDateTime)) {
        window.open(row.url);
      } else {
        let payload = {
          id: row.id,
          customer: this.$store.state.selectedCustomer.value,
          report_name: row.report_name,
          date_created: row.date_created,
          last_updated: moment().format(TIME_FORMAT),
          start_date: moment(row.start_date, "YYYY/MM/DD HH:mm:ss").format(TIME_FORMAT),
          end_date: moment(row.end_date, "YYYY/MM/DD HH:mm:ss").format(TIME_FORMAT),
          status: row.status,
          fields: row.fields,
          emails: row.emails,
          report_grouping: row.report_grouping,
          report_type: row.report_type,
          user_created: row.user_created,
          user_updated: localStorage.getItem("user")
        }
        await this.$api.createNewCustomReportByDateRange(payload);
        this.$router.go();
      }
    },
    async deleteCustomScheduledReport(id, name) {
      var txt;
      if (confirm(`Deseja Excluir o Report Agendado ${id}-${name}?`)) {
        await this.$api.deleteCustomScheduledReportById(id);
        await this.getTableCustomReport();
      }
    },
    async deleteCustomDateReport(id, name) {
      var txt;
      if (confirm(`Deseja Excluir o Report por Data ${id}-${name}?`)) {
        await this.$api.deleteCustomDateReportById(id);
        await this.getTableCustomReport();
      }
    },
    async getTableCustomReport() {
      let customerId = this.$store.state.selectedCustomer.value;
      if (customerId) {
        const reportStatusResponse = await this.getTableCustomReportByCustomer(
          customerId
        );
        let tableData = reportStatusResponse.scheduled;
        tableData.forEach((row) => {
          let scheduleDays = {
            7: "Domingo",
            1: "Segunda-Feira",
            2: "Terça-Feira",
            3: "Quarta-Feira",
            4: "Quinta-Feira",
            5: "Sexta-Feira",
            6: "Sábado-Feira",
            "*": "Todos os Dias",
          };
          let scheduleHours = {
            0: "00",
            1: "01",
            2: "02",
            3: "03",
            4: "04",
            5: "05",
            6: "06",
            7: "07",
            8: "08",
            9: "09",
            10: "10",
            11: "11",
            12: "12",
            13: "13",
            14: "14",
            15: "15",
            16: "16",
            17: "17",
            18: "18",
            19: "19",
            20: "20",
            21: "21",
            22: "22",
            23: "23",
            "*": "Todas as Horas",
          };
          row.schedule_day = scheduleDays[row.schedule_day];
          row.schedule_hour = scheduleHours[row.schedule_hour];
        });
        this.scheduledTable = tableData;
        this.byDateTable = reportStatusResponse.immediate;
      }
    },
    async getTableCustomReportByCustomer(customerId) {
      let params = {
        customer: customerId,
      };
      const response = await this.$api.getCustomReports(params);
      return response.data;
    },
  },
  data() {
    return {
      scheduledTableColumns: [
        "Nome",
        "Data de Criação",
        "Agendamento",
        "Status",
      ],
      scheduledTable: [],
      byDateTableColumns: ["Nome", "Data de Criação", "De", "Até", "Status"],
      byDateTable: [],
      interval: null,
      errorMessage: null,
    };
  },
  created() {
    this.getTableCustomReport();
    setInterval(this.getTableCustomReport, 30000);
  },
  watch: {
    "$store.state.selectedCustomer.value"() {
      this.getTableCustomReport();
    },
  },
  beforeDestroy() {},
};
</script>

<style>
</style>
