export default class Appointment {
  constructor(appointment) {
    this.id = null;
    this.campaigns = "";
    this.monitor_rule = "";
    this.monitor_condition = "";
    this.value = 0;
    this.is_percent = "";
    this.monitor_level = "";
  }
}
