import axios from "axios";

const api = axios.create({
  baseURL: "https://datalake-report.dynad.net/analytics",
  headers: {
    Authorization: getLocalToken()
  }
});

export default {
  getConfigurationList: customerId => {
    return api.get(`/monitor/parameters?customer=${customerId}`);
  },
  getCampaigns: customerId => {
    return api.get(`/monitor/campaigns?customer=${customerId}`);
  },
  getRules: customerId => {
    return api.get(
      `/monitor/parameter/domain?customer=${customerId}&domain=ad_monitor_rule`
    );
  },
  getCondition: customerId => {
    return api.get(
      `/monitor/parameter/domain?customer=${customerId}&domain=ad_monitor_condition`
    );
  },
  getLevels: customerId => {
    return api.get(
      `/monitor/parameter/domain?customer=${customerId}&domain=ad_monitor_level`
    );
  },
  create: parameter => {
    return api.post(`/monitor/parameter`, parameter);
  },
  getToEdit: (id, customerId) => {
    return api.get(`/monitor/parameter/${id}?customer=${customerId}`);
  },
  delete: id => {
    return api.delete(`/monitor/parameter/${id}`);
  }
};

function getToken() {
  const query = window.location.search;
  const token = querystring.parse(query.split("?")[1]).t;
  if (token) {
    localStorage.setItem("__tk__da", token);
  }
  return `Bearer ${token}`;
}

function getLocalToken() {
  const localToken = localStorage.getItem("__tk__da");
  if (localToken) {
    return `Bearer ${localToken}`;
  } else {
    return getToken();
  }
}
