<template>
  <div>
    <div v-show="isLoading">
      <p style="text-align: center">Por favor, aguarde! A busca pode demorar alguns minutos devido a Quantidade de Eventos para longos Intervalos de Datas.</p>
      <div class="loader">
        <PacmanLoader :loading="isLoading" color="#d6611f" />
      </div>
    </div>
    <div v-show="!hasData & !isLoading" class="text-center">
      <h1 class="text-light mt-5">No data available.</h1>
    </div>
    <div v-show="hasData & !isLoading">
      <card type="plain" class="dynad-card">
        <div class="col-12 text-right">
          <div class="form-row">
            <base-input
              v-model="start_date"
              class="col-md-4"
              type="date"
              name="start_date"
              label="Data Inicial"/>
            <base-input
              v-model="end_date"
              class="col-md-4"
              type="date"
              label="Data Final"
              name="end_date"/>
            <div class="col-4 text-left">
              <div class="dynad-btn-side-option">
                <base-button type="primary" class="dynad-btn-report" @click="searchDashBoard">Buscar</base-button>
              </div>
            </div>
          </div>
        </div>
      </card>
      <div class="col-12 p-0 m-0">
        <h3 style="text-align: center">Visão Geral</h3>
        <div v-if="bigLineChart" class="row">
          <div class="col-12">
            <card type="chart">
              <template slot="header">
                <div class="row">
                  <div class="col-sm-6">
                    <h3 class="card-category">{{ $t("dashboard.totalEvents") }}</h3>
                    <h1 class="card-title">{{ $t("dashboard.impressionsClicksCTR") }}</h1>
                  </div>
                  <div class="col-sm-6">
                    <div
                      class="btn-group btn-group-toggle"
                      :class="'float-right'"
                      data-toggle="buttons">
                      <label
                        v-for="(option, index) in bigLineChartCategories"
                        :key="option"
                        class="btn btn-sm btn-primary btn-simple"
                        :class="{ active: bigLineChart.activeIndex === index }"
                        :id="index">
                        <input
                          type="radio"
                          @click="initBigChart(index)"
                          name="options"
                          autocomplete="off"
                          :checked="bigLineChart.activeIndex === index"/>{{ option }}
                      </label>
                    </div>
                  </div>
                </div>
              </template>
              <div class="chart-area">
                <line-chart
                  style="height: 100%"
                  ref="bigChart"
                  chart-id="big-line-chart"
                  :chart-data="bigLineChart.chartData"
                  :gradient-colors="bigLineChart.gradientColors"
                  :gradient-stops="bigLineChart.gradientStops"
                  :extra-options="bigLineChart.extraOptions">
                </line-chart>
              </div>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <card class="dynad-card-metrics">
              <i class="tim-icons icon-image-02 dynad-icon-metrics"></i>
              <h1 class="card-title">{{ totalImpressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</h1>
              <p class="card-text">{{ $t("dashboard.impressions") }}</p>
            </card>
          </div>
          <div class="col-lg-3">
            <card class="dynad-card-metrics">
              <i class="tim-icons icon-tap-02 dynad-icon-metrics"></i>
              <h1 class="card-title">{{ totalClicks.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</h1>
              <p class="card-text">{{ $t("dashboard.clicks") }}</p>
            </card>
          </div>
          <div class="col-lg-3">
            <card class="dynad-card-metrics">
              <i class="tim-icons icon-chart-bar-32 dynad-icon-metrics"></i>
              <h1 class="card-title">{{ parseFloat(totalCTR).toFixed(2).replace(".", ",") + "%" }}</h1>
              <p class="card-text">{{ $t("dashboard.ctr") }}</p>
            </card>
          </div>
          <div class="col-lg-3">
            <card class="dynad-card-metrics">
              <i class="tim-icons icon-zoom-split dynad-icon-metrics"></i>
              <h1 class="card-title">{{ parseFloat(totalViewability).toFixed(2).replace(".", ",") + "%" }}</h1>
              <p class="card-text">{{ $t("dashboard.viewability") }}</p>
            </card>
          </div>
        </div>
        <h3 style="text-align: center">Análise de Dispositivos</h3>
        <div v-if="impressionsDeviceChart" class="row">
          <div class="col-lg-6">
            <card type="chart">
              <template slot="header">
                <h5 class="card-category">{{ $t("dashboard.impressionsDevice") }}</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-laptop dynad-icon-devices"></i>{{ devices }}
                </h3>
              </template>
              <div class="chart-area">
                <bar-chart
                  style="height: 100%"
                  chart-id="orange-bar-device-chart"
                  ref="impressionsDeviceChart"
                  :chart-data="impressionsDeviceChart.chartData"
                  :gradient-stops="impressionsDeviceChart.gradientStops"
                  :extra-options="impressionsDeviceChart.extraOptions">
                </bar-chart>
              </div>
            </card>
          </div>
          <div v-if="clicksDeviceChart" class="col-lg-6">
            <card type="chart">
              <template slot="header">
                <h5 class="card-category">{{ $t("dashboard.clicksDevice") }}</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-laptop dynad-icon-devices"></i>{{ devices }}
                </h3>
              </template>
              <div class="chart-area">
                <bar-chart
                  style="height: 100%"
                  ref="clicksDeviceChart"
                  chart-id="orange-bar-browser-chart"
                  :chart-data="clicksDeviceChart.chartData"
                  :gradient-stops="clicksDeviceChart.gradientStops"
                  :extra-options="clicksDeviceChart.extraOptions">
                </bar-chart>
              </div>
            </card>
          </div>
        </div>
        <h3 style="text-align: center">Análise de Formatos</h3>
        <div v-if="impressionsFormatChart" class="row">
          <div class="col-lg-4">
            <card type="chart">
              <template slot="header">
                <h5 class="card-category">{{ $t("dashboard.impressionsFormat") }}</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-components dynad-icon-devices"></i>{{ formats }}
                </h3>
              </template>
              <div class="chart-area">
                <bar-chart
                  style="height: 100%"
                  chart-id="orange-bar-device-chart"
                  ref="impressionsFormatChart"
                  :chart-data="impressionsFormatChart.chartData"
                  :gradient-stops="impressionsFormatChart.gradientStops"
                  :extra-options="impressionsFormatChart.extraOptions">
                </bar-chart>
              </div>
            </card>
          </div>
          <div v-if="clicksFormatChart" class="col-lg-4">
            <card type="chart">
              <template slot="header">
                <h5 class="card-category">{{ $t("dashboard.clicksFormat") }}</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-components dynad-icon-devices"></i>{{ formats }}
                </h3>
              </template>
              <div class="chart-area">
                <bar-chart
                  style="height: 100%"
                  ref="clicksFormatChart"
                  chart-id="orange-bar-browser-chart"
                  :chart-data="clicksFormatChart.chartData"
                  :gradient-stops="clicksFormatChart.gradientStops"
                  :extra-options="clicksFormatChart.extraOptions">
                </bar-chart>
              </div>
            </card>
          </div>
          <div v-if="ctrFormatChart" class="col-lg-4">
            <card type="chart">
              <template slot="header">
                <h5 class="card-category">{{ $t("dashboard.ctrFormat") }}</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-components dynad-icon-devices"></i>{{ formats }}
                </h3>
              </template>
              <div class="chart-area">
                <bar-chart
                  style="height: 100%"
                  ref="ctrFormatChart"
                  chart-id="orange-bar-browser-chart"
                  :chart-data="ctrFormatChart.chartData"
                  :gradient-stops="ctrFormatChart.gradientStops"
                  :extra-options="ctrFormatChart.extraOptions">
                </bar-chart>
              </div>
            </card>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <sorted-table :values="tableFormats">
              <thead>
              <tr>
                <th scope="col" style="width: 40%" class="dynad-table-sorter">
                  <sort-link name="format">Formato</sort-link>
                </th>
                <th scope="col" style="width: 20%" class="dynad-table-sorter">
                  <sort-link name="impressions">Impressions</sort-link>
                </th>
                <th scope="col" style="width: 20%" class="dynad-table-sorter">
                  <sort-link name="clicks">Clicks</sort-link>
                </th>
                <th scope="col" style="width: 20%" class="dynad-table-sorter">
                  <sort-link name="ctr">CTR</sort-link>
                </th>
              </tr>
              </thead>
              <template #body="sort">
                <tbody>
                <tr v-for="value in sort.values" :key="value.id">
                  <td>{{ value.format }}</td>
                  <td>{{ value.impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                  <td>{{ value.clicks.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                  <td>{{ parseFloat(value.ctr).toFixed(4).replace(".", ",") + "%" }}</td>
                </tr>
                </tbody>
              </template>
            </sorted-table>
          </div>
        </div>
        <h3 style="text-align: center">Análise de Backup</h3>
        <div v-if="impressionsBackupChart" class="row">
          <div class="col-lg-4">
            <card type="chart">
              <template slot="header">
                <h5 class="card-category">{{ $t("dashboard.backupAnalysis") }}</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-single-copy-04 dynad-icon-devices"></i>
                </h3>
              </template>
              <div class="chart-area">
                <bar-chart
                  style="height: 100%"
                  chart-id="orange-bar-device-chart"
                  ref="impressionsBackupChart"
                  :chart-data="impressionsBackupChart.chartData"
                  :gradient-stops="impressionsBackupChart.gradientStops"
                  :extra-options="impressionsBackupChart.extraOptions">
                </bar-chart>
              </div>
            </card>
          </div>
          <div class="col-lg-8">
            <div class="row">
              <sorted-table :values="tableBackups">
                <thead>
                <tr>
                  <th scope="col" style="width: 40%" class="dynad-table-sorter">
                    <sort-link name="type">Tipo</sort-link>
                  </th>
                  <th scope="col" style="width: 20%" class="dynad-table-sorter">
                    <sort-link name="impressions">Impressions</sort-link>
                  </th>
                  <th scope="col" style="width: 20%" class="dynad-table-sorter">
                    <sort-link name="clicks">Clicks</sort-link>
                  </th>
                  <th scope="col" style="width: 20%" class="dynad-table-sorter">
                    <sort-link name="ctr">CTR</sort-link>
                  </th>
                </tr>
                </thead>
                <template #body="sort">
                  <tbody>
                  <tr v-for="value in sort.values" :key="value.id">
                    <td>{{ value.type }}</td>
                    <td>{{ value.impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                    <td>{{ value.clicks.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                    <td>{{ parseFloat(value.ctr).toFixed(4).replace(".", ",") + "%" }}</td>
                  </tr>
                  </tbody>
                </template>
              </sorted-table>
            </div>
            <div v-if="tableCampaignsBackup.length > 0" class="row">
              <sorted-table :values="tableCampaignsBackup">
                <thead>
                <tr>
                  <th scope="col" style="width: 40%" class="dynad-table-sorter">
                    <sort-link name="campaign">Campaign</sort-link>
                  </th>
                  <th scope="col" style="width: 20%" class="dynad-table-sorter">
                    <sort-link name="impressions">Impressions</sort-link>
                  </th>
                  <th scope="col" style="width: 20%" class="dynad-table-sorter">
                    <sort-link name="clicks">Clicks</sort-link>
                  </th>
                  <th scope="col" style="width: 20%" class="dynad-table-sorter">
                    <sort-link name="ctr">CTR</sort-link>
                  </th>
                </tr>
                </thead>
                <template #body="sort">
                  <tbody>
                  <tr v-for="value in sort.values" :key="value.id">
                    <td>{{ value.campaign }}</td>
                    <td>{{ value.impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                    <td>{{ value.clicks.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                    <td>{{ parseFloat(value.ctr).toFixed(4).replace(".", ",") + "%" }}</td>
                  </tr>
                  </tbody>
                </template>
              </sorted-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import scripts from "./Dashboard/scripts";
export default scripts;
</script>
<style>
.loader {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  background-color: rgba(23, 23, 23, 0.8);
  align-items: center;
  justify-content: center;
}
.loader * {
  transform: scale(1.5);
}
</style>
