<template>
  <div>
    <div>
      <div v-show="isLoading">
        <p style="text-align: center">
          Por favor, aguarde! A busca pode demorar alguns minutos.
        </p>
        <div class="loader">
          <PacmanLoader :loading="isLoading" color="#d6611f" />
        </div>
      </div>
      <div v-show="!isLoading" class="card">
        <div class="d-flex w-100 flex-column">
          <div class="row m-0 border-bottom border-light py-2">
            <a
              class="
                text-uppercase text-white
                d-flex
                align-items-center
                ml-4
                title
              "
              >Configurações</a
            >
            <router-link
              to="/apontamentos/configuracao/criarParametro"
              class="ml-auto mr-3 d-flex align-items-center"
            >
              <base-button type="primary" class="dynad-btn-report px-4">
                Criar Parâmetro
              </base-button>
            </router-link>
          </div>
        </div>
        <div v-show="!hasData" class="text-center">
          <h1 class="text-light mt-5">Sem parâmetros disponiveis</h1>
        </div>
        <div v-show="hasData & !isLoading" class="px-2 mt-4">
          <table class="table table-responsive-xl scroll-dynad depth-table table-hover">
            <thead class="text-center">
              <tr>
                <th>#</th>
                <th>Indicador/Regra</th>
                <th>Condição Cadastrada</th>
                <th>Data</th>
                <th>Ocorrências</th>
                <th>Campanha(s)</th>
                <th>Grau Cadastrado</th>
                <th />
              </tr>
            </thead>
            <tbody class="text-center" style="background-color: #232323">
              <tr v-for="(appointment, index) in appointments" :key="index">
                <th>{{ appointment.id }}</th>
                <td>
                  {{ appointment.monitor_rule.monitor_rule }}
                </td>
                <td class="text-nowrap">
                  {{ appointment.monitor_condition.monitor_condition }}
                  {{ appointment.value }}
                  {{ appointment.is_percent ? "%" : "" }}
                </td>
                <td class="text-nowrap">{{ appointment.date_created }}</td>
                <td>{{ appointment.monitor_count }}</td>
                <td>
                  {{
                    appointment.campaigns.map((cmp) => cmp.campaign).join(", ")
                  }}
                </td>
                <td>{{ appointment.monitor_level.monitor_level }}</td>
                <td>
                  <router-link
                    :to="`/apontamentos/configuracao/criarParametro?id=${appointment.id}`"
                  >
                    <button
                      type="button"
                      rel="tooltip"
                      class="btn btn-icon btn-fab btn-default btn-sm mr-1 mt-1"
                      @click="editParameter(appointment)"
                    >
                      <i class="tim-icons icon-pencil" />
                    </button>
                  </router-link>
                  <button
                    type="button"
                    class="btn btn-icon btn-fab btn-default btn-sm mr-1 mt-1"
                    @click="deleteParameter(appointment.id)"
                  >
                    <i class="tim-icons icon-trash-simple" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import parameterAPI from "../../plugins/api/monitor/paremeter";
import parameterUtils from "../../Utils/parameter";
import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";

export default {
  components: { PacmanLoader },
  data() {
    return {
      appointments: [],
      hasData: false,
      isLoading: false,
    };
  },
  watch: {
    "$store.state.selectedCustomer.value"() {
      this.getAllParameters(this.$store.state.selectedCustomer.value);
    },
  },
  mounted() {
    this.getAllParameters(this.$store.state.selectedCustomer.value);
  },
  methods: {
    editParameter(parameter) {
      this.$store.commit("setRule", parameter);
    },
    async deleteParameter(id) {
      await parameterAPI.delete(id);
      this.appointments = this.appointments.filter(
        (appointment) => appointment.id !== id
      );
    },
    async getAllParameters(customerId) {
      this.isLoading = true;
      this.hasData = false;
      const parameter = await parameterUtils.getParameter(customerId);
      const { data: campaign } = await parameterAPI.getCampaigns(customerId);
      const { data: configList } = await parameterAPI.getConfigurationList(
        customerId
      );

      if (Object.keys(configList).length > 0) {
        this.hasData = true;
        this.isLoading = false;
      } else {
        this.hasData = false;
        this.isLoading = false;
      }

      this.$store.commit("setRules", parameter);

      this.appointments = configList.map((config) => {
        const rule = parameter.rules.find(
          (configRule) => configRule.id === config["monitor_rule"]
        );
        const condition = parameter.conditions.find(
          (configCondition) => configCondition.id === config.monitor_condition
        );
        const monitor_level = parameter.levels.find(
          (configLevel) => configLevel.id === config.monitor_level
        );

        return {
          ...config,
          monitor_rule: rule,
          monitor_condition: condition,
          monitor_level,
          campaigns: campaign.filter((cmp) =>
            config.campaigns.includes(Number(cmp.campaign))
          ),
        };
      });
    },
  },
};
</script>
<style>
.title {
  font-size: 15px;
}
.dynad-btn-report {
  height: 30px !important;
}
.depth-table {
  max-height: 70vh !important;
}
</style>