export default {
  colors: {
    default: "#232323",
    primary: "#d6611f",
    info: "#1d8cf8",
    danger: "#ec250d",
    teal: "#00d6b4",
    primaryGradient: ['rgba(214, 97, 31, 0.2)', 'rgba(72,72,176,0.0)', 'rgba(119,52,169,0)'],
  }
}
