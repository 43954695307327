<template>
  <div>
    <div class="card mb-2">
      <div class="d-flex w-100 flex-column">
        <div class="row m-0 border-bottom border-light py-2">
          <a
            class="
              text-uppercase text-white
              d-flex
              align-items-center
              ml-4
              title
            "
            >Novo parâmetro de apontamento</a
          >
        </div>
        <div>
          <div class="row py-5 mx-auto px-4 border-bottom">
            <div class="col-11 col-sm-9 col-md-6 col-lg-6">
              <label class="form-label text-white" for="campaign"
                >Campanha</label
              >
              <vSelect
                multiple
                v-model="selected.campaigns"
                :options="campaign"
                label="campaign_name"
                placeholder="Selecione a Campanha(s)"
                :close-on-select="false"
                class="style-input"
              >
              </vSelect>
            </div>
          </div>
          <div class="row mx-auto px-4 py-4">
            <div class="col-5 select pr-0">
              <label for="rule" class="text-white">Indicador/Regra</label>
              <vSelect
                id="rule"
                class="style-chooser"
                label="monitor_rule"
                :options="this.$store.state.rules.rules"
                v-model="selected.monitor_rule"
              />
            </div>
            <div class="col-2 select pr-0">
              <label for="condition" class="text-white">Condição</label>
              <vSelect
                id="condition"
                class="style-chooser"
                label="monitor_condition"
                :options="this.$store.state.rules.conditions"
                v-model="selected.monitor_condition"
              />
            </div>
            <div class="col-1 mt-3 pr-0 pt-1">
              <input
                type="text"
                class="form-control"
                v-model="selected.value"
              />
            </div>
            <div class="col-2 mt-3 pt-1 pr-0 pl-3 text-white">
              <div class="form-check">
                <label class="mr-2 text-white" for="checkbox">%</label>
                <label class="form-check-label">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="selected.is_percent"
                  />
                  <span class="form-check-sign"></span>
                  <label for="checkbox" class="text-white">Percentual</label>
                </label>
              </div>
            </div>
            <div class="col-2 select px-0">
              <label for="level" class="text-white">Grau do Apontamento</label>
              <vSelect
                id="level"
                class="style-chooser"
                label="monitor_level"
                :options="this.$store.state.rules.levels"
                v-model="selected.monitor_level"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-end">
      <div class="col-auto px-0">
        <router-link
          to="/apontamentos/configuracao"
          type="submit"
          class="btn btn-neutral text-info"
        >
          Cancelar
        </router-link>
      </div>
      <div class="col-auto pl-2">
        <button
          v-if="selected.id > 0"
          type="submit"
          class="btn btn-secondary"
          @click="createParameter()"
        >
          Atualizar
        </button>
        <button
          v-else
          type="submit"
          class="btn btn-secondary"
          v-on:click="createParameter()"
        >
          Salvar
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import Multiselect from "vue-multiselect";
import Appointment from "../../models/appointment";
import dateUtil from "../../Utils/date-util";
import parameterAPI from "../../plugins/api/monitor/paremeter";

export default {
  components: {
    vSelect,
    Multiselect,
  },
  data() {
    return {
      campaign: [],
      rules: [],
      conditions: [],
      value: "0",
      levels: [],
      parameter: [],
      selected: new Appointment({}),
    };
  },
  mounted() {
    this.getCampaigns(this.$store.state.selectedCustomer.value);
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (newRoute) {
        if (newRoute.query.id) {
          try {
            this.selected = this.$store.state.rule;
          } catch (error) {
            console.error(error);
          }
        }
      },
    },
  },
  methods: {
    getCampaigns(customerId) {
      parameterAPI.getCampaigns(customerId).then(({ data }) => {
        this.campaign = data;
      });
    },
    createParameter() {
      if (this.selected.id) {
        this.selected = {
          ...this.selected,
          monitor_level: this.selected.monitor_level.id,
          monitor_rule: this.selected.monitor_rule.id,
          campaigns: this.selected.campaigns.map((cmp) => cmp.campaign),
          monitor_condition: this.selected.monitor_condition.id,
          is_percent: Boolean(this.selected.is_percent),
          last_updated: dateUtil.formatYearMonthDayTime(
            new Date().toISOString()
          ),
        };
        parameterAPI.create(this.selected);
        this.$router.back();
      } else {
        this.selected = {
          ...this.selected,
          monitor_level: this.selected.monitor_level.id,
          monitor_rule: this.selected.monitor_rule.id,
          campaigns: this.selected.campaigns.map((cmp) => cmp.campaign),
          monitor_condition: this.selected.monitor_condition.id,
          is_percent: Boolean(this.selected.is_percent),
          date_created: dateUtil.formatYearMonthDayTime(
            new Date().toISOString()
          ),
          last_updated: dateUtil.formatYearMonthDayTime(
            new Date().toISOString()
          ),
          customer: this.$store.state.selectedCustomer.value,
          id: null,
        };
        parameterAPI.create(this.selected);
        this.$router.back();
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
:root {
  --dynad-color: #d6611f;
}

.title {
  font-size: 15px;
}
.style-input .vs__dropdown-toggle,
.style-input .vs__dropdown-menu {
  background: var(--dynad-color);
  border: none;
  text-transform: lowercase;
  font-variant: small-caps;
}
.style-input .vs__search::placeholder {
  background: #d6611f;
  border: none;
  color: white;
  text-transform: lowercase;
  font-variant: small-caps;
}
.style-input * {
  color: white;
}
.style-input .vs__selected {
  color: var(--dynad-color) !important;
}

.style-input .vs__clear,
.style-input .vs__open-indicator {
  fill: rgba(255, 255, 255, 0.7);
}
/* .select {
  min-width: 150px;
}
.multiselect {
  background: var(--dynad-color);
  color: var(--dynad-color);
  font-size: 13px;
  border-radius: 4px;
}
.multiselect__input {
  background: var(--dynad-color);
  font-size: 13px;
}
.multiselect__input:focus {
  color: var(--dynad-color);
  font-size: 13px;
}

.multiselect__option {
  background-color: var(--dynad-color);
} */
/* .multiselect__option:hover {
  background-color: white;
  color: var(--dynad-color);
} */
/* .multiselect__option--highlight:after {
  background: var(--dynad-color);
  color: black;
}
.multiselect__option--highlight:hover {
  background: #5896fb;
  color: white;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: var(--dynad-color);
  color: white;
}
.multiselect__option--selected.multiselect__option--highlight:hover {
  background: #525f7f;
  color: white;
  border: none !important;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ffffff;
  content: attr(data-deselect);
  color: var(--dynad-color);
}
.multiselect__tags {
  background-color: var(--dynad-color);
}
.multiselect__tag {
  background: white;
  color: var(--dynad-color);
}
.multiselect__tags .multiselect__tag-icon::after {
  color: var(--dynad-color);
}

.multiselect__tag-icon:hover {
  background: red;
}
.multiselect__placeholder {
  margin-bottom: 0px;
  padding-top: 0px;
  color: white;
} */
/* .vs__dropdown-toggle {
     height: 40px !important;
     font-size: 30px !important;
     margin-top: 0px !important;
     padding-top: 0px !important;
  } */

/* .style-chooser{
    height: 40px !important;
  } */
</style>