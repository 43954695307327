import { BaseTable } from "@/components";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import * as chartConfigs from "@/components/Charts/config";
import TaskList from "../TaskList";
import UserTable from "../UserTable";
import config from "@/config";
import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";
import moment from "moment";

export default {
  components: {
    BaseTable,
    LineChart,
    BarChart,
    TaskList,
    UserTable,
    PacmanLoader
  },
  data() {
    return {
      tableFormats: [],
      tableBackups: [],
      tableCampaignsBackup: [],
      hasData: false,
      isLoading: false,
      start_date: "",
      end_date: "",
      selectedCustomer: this.$store.state.selectedCustomer.value,
      viewData: {},
      dashboardDateTimeRange: [
        { label: "Last 24 Hours", value: 1 },
        { label: "Last 7 Days", value: 7 },
        { label: "Last 31 Days", value: 31 }
      ],
      totalImpressions: "0",
      totalClicks: "0",
      totalCTR: "0",
      totalViewability: "0",
      impressionsDevice: "0",
      clicksDevice: "0",
      devices: 0,
      formats: 0,
      bigLineChart: {
        allData: null,
        activeIndex: 0,
        chartData: {
          datasets: [{}],
          labels: null
        },
        extraOptions: chartConfigs.lineChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      },
      impressionsDeviceChart: null,
      clicksDeviceChart: null,
      impressionsFormatChart: null,
      clicksFormatChart: null,
      ctrFormatChart: null,
      impressionsBackupChart: null,
    };
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return this.$t("dashboard.chartCategories");
    }
  },
  methods: {
    initBigChart(index) {
      let chartData = {
        datasets: [
          {
            fill: true,
            borderColor: config.colors.primary,
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: config.colors.primary,
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: config.colors.primary,
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: this.bigLineChart.allData[index]
          }
        ],
        labels: this.bigLineChart.chartData.labels || []
      };
      this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;
    },
    generateBarChart(labels, data, percentages, type) {
      let formattedPercentages = [];
      for (let i = 0; i < percentages.length; i++ ) {
        formattedPercentages.push(parseFloat(percentages[i]).toFixed(4) + "%");
      }
      this.$store.commit("setFormattedPercentages".concat(type), formattedPercentages);
      let chartOptions = { ...chartConfigs.barChartOptions };
      if (type == "DeviceImpressions") {
        chartOptions.tooltips = {
          ...chartOptions.tooltips,
          callbacks: {
            label: tooltipItems => {
              let formattedPercentages = this.$store.state.formattedPercentagesDeviceImpressions;
              return `${tooltipItems.yLabel} (${formattedPercentages[tooltipItems.index]})`;
            }
          }
        };
      }
      if (type == "DeviceClicks") {
        chartOptions.tooltips = {
          ...chartOptions.tooltips,
          callbacks: {
            label: tooltipItems => {
              let formattedPercentages = this.$store.state.formattedPercentagesDeviceClicks;
              return `${tooltipItems.yLabel} (${formattedPercentages[tooltipItems.index]})`;
            }
          }
        };
      }
      if (type == "FormatImpressions") {
        chartOptions.tooltips = {
          ...chartOptions.tooltips,
          callbacks: {
            label: tooltipItems => {
              let formattedPercentages = this.$store.state.formattedPercentagesFormatImpressions;
              return `${tooltipItems.yLabel} (${formattedPercentages[tooltipItems.index]})`;
            }
          }
        };
      }
      if (type == "FormatClicks") {
        chartOptions.tooltips = {
          ...chartOptions.tooltips,
          callbacks: {
            label: tooltipItems => {
              let formattedPercentages = this.$store.state.formattedPercentagesFormatClicks;
              return `${tooltipItems.yLabel} (${formattedPercentages[tooltipItems.index]})`;
            }
          }
        };
      }
      if (type == "FormatCTR") {
        chartOptions.tooltips = {
          ...chartOptions.tooltips,
          callbacks: {
            label: tooltipItems => {
              return `${tooltipItems.yLabel}`;
            }
          }
        };
      }
      if (type == "BackupImpressions") {
        chartOptions.tooltips = {
          ...chartOptions.tooltips,
          callbacks: {
            label: tooltipItems => {
              let formattedPercentages = this.$store.state.formattedPercentagesBackupImpressions;
              return `${tooltipItems.yLabel} (${formattedPercentages[tooltipItems.index]})`;
            }
          }
        };
      }
      return {
        extraOptions: chartOptions,
        chartData: {
          labels,
          datasets: [
            {
              label: "Events",
              fill: true,
              borderColor: config.colors.primary,
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              data
            }
          ]
        },
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0]
      };
    },
    async getCustomers() {
      const response = await this.$api.auth();
      const user = response["user"];
      this.$store.commit("setUser", user);
      const customers = response["customers"];
      if (Array.isArray(customers) && customers.length > 0) {
        this.$store.commit("setCustomers", customers);
      }
    },
    async searchDashBoard() {
      this.getData(this.$store.state.selectedCustomer.value, this.start_date, this.end_date);
    },
    async getData(customer, sd, ed) {
      this.isLoading = true;
      this.hasData = false;
      let start_date = `${sd} 00:00:00`;
      let end_date = `${ed} 23:59:59`;
      const response = await this.$api.getDashboardData({ customer, start_date, end_date });
      if (Object.keys(response).length > 0) {
        this.hasData = true;
        this.isLoading = false;
        this.viewData = response;
        const {
          dates,
          impressions,
          clicks,
          ctr,
          total_impressions,
          total_clicks,
          total_ctr,
          total_viewability,
          devices,
          device_impressions_types,
          device_clicks_types,
          device_impressions,
          device_impressions_percentage,
          device_clicks,
          device_clicks_percentage,
          formats,
          format_impressions_types,
          format_impressions,
          format_impressions_percentage,
          format_clicks_types,
          format_clicks,
          format_clicks_percentage,
          format_ctr_types,
          format_ctr,
          backup_impressions_types,
          backup_impressions,
          backup_impressions_percentage,
          table_formats,
          table_backups,
          table_campaigns_backup
        } = response;

        this.bigLineChart.allData = [impressions, clicks, ctr];
        this.bigLineChart.chartData.labels = dates;
        this.initBigChart(0);

        this.totalImpressions = total_impressions;
        this.totalClicks = total_clicks;
        this.totalCTR = total_ctr;
        this.totalViewability = total_viewability;
        this.devices = devices;
        this.formats = formats;
        this.tableFormats = table_formats;
        this.tableBackups = table_backups;
        this.tableCampaignsBackup = table_campaigns_backup;

        this.impressionsDeviceChart = this.generateBarChart(
          device_impressions_types,
          device_impressions,
          device_impressions_percentage,
          "DeviceImpressions"
        );
        this.clicksDeviceChart = this.generateBarChart(
          device_clicks_types,
          device_clicks,
          device_clicks_percentage,
          "DeviceClicks"
        );
        this.impressionsFormatChart = this.generateBarChart(
          format_impressions_types,
          format_impressions,
          format_impressions_percentage,
          "FormatImpressions"
        );
        this.clicksFormatChart = this.generateBarChart(
          format_clicks_types,
          format_clicks,
          format_clicks_percentage,
          "FormatClicks"
        );
        this.ctrFormatChart = this.generateBarChart(
          format_ctr_types,
          format_ctr,
          [],
          "FormatCTR"
        );
        this.impressionsBackupChart = this.generateBarChart(
          backup_impressions_types,
          backup_impressions,
          backup_impressions_percentage,
          "BackupImpressions"
        );
      } else {
        this.hasData = false;
        this.isLoading = false;
      }
    }
  },
  mounted() {
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = "en";
      this.$rtl.enableRTL();
    }
    this.getCustomers();
    let today = moment();
    this.end_date = today.format("YYYY-MM-DD");
    this.start_date = this.end_date;
    this.getData(this.$store.state.selectedCustomer.value, this.start_date, this.end_date);
  },
  watch: {
    "$store.state.selectedCustomer.value"() {
      let today = moment();
      this.end_date = today.format("YYYY-MM-DD");
      this.start_date = this.end_date;
      this.getData(this.$store.state.selectedCustomer.value, this.start_date, this.end_date);
    }
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = "en";
      this.$rtl.disableRTL();
    }
  }
};
