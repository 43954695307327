<template>
  <card type="plain">
    <div class="col-12 text-right">
      <div class="form-row">
        <base-input
          v-model="startDate"
          class="col-md-4"
          type="date"
          name="start_date"
          label="Data Inicial"
        />
        <base-input
          v-model="endDate"
          class="col-md-4"
          type="date"
          label="Data Final"
          name="end_date"
        />
        <div class="col-4 text-left">
          <div class="dynad-btn-side-option">
            <base-button type="primary" class="dynad-btn-report" @click="searchData">Buscar</base-button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isLoading">
      <p style="text-align: center">Por favor, aguarde! A busca pode demorar alguns minutos devido a Quantidade de Eventos para longos Intervalos de Datas.</p>
      <div class="loader">
        <PacmanLoader :loading="isLoading" color="#d6611f" />
      </div>
    </div>
    <div v-show="!isLoading & !hasData" class="text-center">
      <h1 class="text-light mt-5">Nenhum Evento Encontrado</h1>
    </div>
    <div v-show="!isLoading & hasData" class="col-12 text-right">
      <div class="row">
        <div class="col-2">
          <base-input label="Filtrar Estado">
            <select
              id="scheduleHour"
              @change="filterUF"
              v-model="selectedUF"
              class="form-control btn btn-secondary text-white dynad-select"
            >
              <option
                v-for="option in UFs"
                :key="option.value"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </base-input>
        </div>
        <div class="dynad-btn-side-option">
          <base-button type="primary" class="dynad-btn-report" @click="toggleClicksMap">Exibir Cliques</base-button>
        </div>
        <div class="dynad-btn-side-option">
          <base-button type="primary" class="dynad-btn-report" @click="toggleCoverageMap">Exibir Cobertura</base-button>
        </div>
        <div class="dynad-btn-side-option">
          <base-button type="primary" class="dynad-btn-report" @click="toggleImpressionsMap">Exibir Impressões</base-button>
        </div>
        <div v-show="showImpressions" class="dynad-btn-side-option">
          <base-button type="primary" class="dynad-btn-report" @click="toggleGradient">Alterar Gradiente</base-button>
        </div>
        <div v-show="showImpressions" class="dynad-btn-side-option">
          <base-button type="primary" class="dynad-btn-report" @click="toggleRadius">Alterar Raio</base-button>
        </div>
        <div v-show="showImpressions" class="dynad-btn-side-option">
          <base-button type="primary" class="dynad-btn-report" @click="toggleOpacity">Alterar Opacidade</base-button>
        </div>
      </div>
      <h1 class="card-title text-left">{{title}}</h1>
      <div id="map" class="map"></div>
    </div>
  </card>
</template>
<script>
import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";
export default {
  components: {
    PacmanLoader
  },
  methods: {
    async filterUF() {
      let data = [];
      let dataFilterLocations = [];
      if (this.showImpressions == true) {
        data = this.tableData["impressions"];
      } else if (this.showClicks == true) {
        data = this.tableData["clicks"];
      } else if (this.showCoverageArea == true) {
        data = this.tableData["clicks"];
        dataFilterLocations = this.tableData["filterLocations"];
      }
      let selectedUF = this.selectedUF;
      let filteredData = [];
      let filteredFilterLocations = [];
      if (selectedUF == 0) {
        filteredData = data;
        filteredFilterLocations = [];
      } else {
        filteredData = data.filter(function (item) {
          return item.state == selectedUF;
        }, selectedUF);
        if (this.showCoverageArea == true) {
          filteredFilterLocations = dataFilterLocations.filter(function (item) {
            return item.state == selectedUF;
          }, selectedUF);
        }
      }
      if (this.showImpressions == true) {
        await this.createImpressionsMap(filteredData);
      } else if (this.showClicks == true) {
        await this.createClicksMap(filteredData);
      } else if (this.showCoverageArea == true) {
        await this.createCoverageMap(filteredData, filteredFilterLocations);
      }
    },
    async getClicksAndImpressionsByCustomer(customerId, start_date, end_date) {
      let params = {
        customer: customerId,
        start_date: start_date,
        end_date: end_date
      }
      const response = await this.$api.getImpressionsAndClicks(params);
      return response.data;
    },
    async getClicksAndImpressions() {
      let customerId = this.$store.state.selectedCustomer.value;
      let start_date = this.startDate;
      let end_date = this.endDate;
      if(customerId && start_date && end_date) {
        const responseData = await this.getClicksAndImpressionsByCustomer(customerId, start_date, end_date);
        this.tableData = responseData;
        return responseData;
      }
      else {
        alert("Data Inicial e Data Final São Obrigatórios!")
        return {
          "impressions": []
        }
      }
    },
    async createGoogleMaps() {
      let myLatlng = new window.google.maps.LatLng(-14.1868191, -48.3358824);
      let mapOptions = {
        zoom: 4,
        center: myLatlng,
        scrollwheel: true,
        mapTypeControl: false,
        streetViewControl: false,
        styles: [{
          "elementType": "geometry",
          "stylers": [{
            "color": "#232323"
          }]
        },
          {
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#ffffff"
            }]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [{
              "color": "#262626"
            }]
          },
          {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [{
              "color": "#d6611f"
            }]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#ffffff"
            }]
          },
          {
            "featureType": "administrative.province",
            "elementType": "geometry.stroke",
            "stylers": [{
              "color": "#d6611f"
            }]
          },
          {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [{
              "color": "#d6611f"
            }]
          },
          {
            "featureType": "landscape.natural",
            "elementType": "geometry",
            "stylers": [{
              "color": "#323232"
            }]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [{
              "color": "#323232"
            }]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#ffffff"
            }]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.stroke",
            "stylers": [{
              "color": "#262626"
            }]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [{
              "color": "#323232"
            }]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#323232"
            }]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [{
              "color": "#323232"
            }]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#ffffff"
            }]
          },
          {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [{
              "color": "#262626"
            }]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [{
              "color": "#323232"
            }]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [{
              "color": "#323232"
            }]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [{
              "color": "#d6611f"
            }]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#ffffff"
            }]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.stroke",
            "stylers": [{
              "color": "#262626"
            }]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#ffffff"
            }]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.stroke",
            "stylers": [{
              "color": "#262626"
            }]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry.fill",
            "stylers": [{
              "color": "#323232"
            }]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [{
              "color": "#323232"
            }]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [{
              "color": "#232323"
            }]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#ffffff"
            }]
          }
        ]
      };
      let map = new window.google.maps.Map(
        document.getElementById("map"),
        mapOptions
      );
      if (this.selectedUF > 0) {
        map.setCenter({
          lat: this.centerPointsUF[this.selectedUF]["latitude"],
          lng: this.centerPointsUF[this.selectedUF]["longitude"]
        });
        map.setZoom(12);
      }
      return map
    },
    async createCoverageMap(clicks, filterLocations) {
      let map = await this.createGoogleMaps();
      let i;
      for (i = 0; i < filterLocations.length; i++) {
        new google.maps.Circle({
          strokeColor: "#ffffff",
          strokeOpacity: 1,
          strokeWeight: 1,
          fillColor: "#ffffff",
          fillOpacity: 0.25,
          map,
          center: new google.maps.LatLng(filterLocations[i]["latitude"], filterLocations[i]["longitude"]),
          radius: 500,
        });
      }
      const svgMarker = {
        path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
        fillColor: "#d6611f",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 1,
        anchor: new google.maps.Point(15, 30),
      };
      for (i = 0; i < clicks.length; i++) {
        new google.maps.Marker({
          position: new google.maps.LatLng(clicks[i]["latitude"], clicks[i]["longitude"]),
          icon: svgMarker,
          map: map,
          optimized: true
        });
      }
      this.map = map;
    },
    async createClicksMap(impressionsAndClicks) {
      let map = await this.createGoogleMaps();
      const svgMarker = {
        path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
        fillColor: "#d6611f",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 1,
        anchor: new google.maps.Point(15, 30),
      };
      let i;
      for (i = 0; i < impressionsAndClicks.length; i++) {
        new google.maps.Marker({
          position: new google.maps.LatLng(impressionsAndClicks[i]["latitude"], impressionsAndClicks[i]["longitude"]),
          icon: svgMarker,
          map: map,
          optimized: true
        });
      }
      this.map = map;
    },
    async createImpressionsMap(impressionsAndClicks) {
      let map = await this.createGoogleMaps();
      let i;
      let geoPoints = []
      for (i = 0; i < impressionsAndClicks.length; i++) {
        geoPoints.push({
          location: new google.maps.LatLng(impressionsAndClicks[i]["latitude"], impressionsAndClicks[i]["longitude"]),
          weight: impressionsAndClicks[i]["events"]
        });
      }
      let heatmap = new window.google.maps.visualization.HeatmapLayer({
        data: geoPoints,
        map: map,
      });
      this.map = map;
      this.heatmap = heatmap;
    },
    async searchData() {
      this.selectedUF = "0";
      this.title = "Impressões";
      this.tableData = "";
      this.isLoading = true;
      this.showClicks = false;
      this.showImpressions = true;
      this.showCoverageArea = false;
      let impressionsAndClicks = await this.getClicksAndImpressions();
      let impressions = impressionsAndClicks["impressions"]
      if (impressions.length > 0) {
        this.isLoading = false;
        this.hasData = true;
        await this.createImpressionsMap(impressions);
      } else {
        this.isLoading = false;
        this.hasData = false;
      }
    },
    async toggleClicksMap() {
      this.selectedUF = "0";
      this.title = "Cliques";
      this.showClicks = true;
      this.showImpressions = false;
      this.showCoverageArea = false;
      let clicks = this.tableData["clicks"];
      await this.createClicksMap(clicks);
    },
    async toggleCoverageMap() {
      this.selectedUF = "0";
      this.title = "Área De Cobertura";
      this.showClicks = false;
      this.showImpressions = false;
      this.showCoverageArea = true;
      let clicks = this.tableData["clicks"];
      await this.createCoverageMap(clicks, []);
    },
    async toggleImpressionsMap() {
      this.selectedUF = "0";
      this.title = "Impressões";
      this.showClicks = false;
      this.showImpressions = true;
      this.showCoverageArea = false;
      let impressions = this.tableData["impressions"]
      await this.createImpressionsMap(impressions);
    },
    toggleGradient() {
      const gradient = [
        "rgba(255,255,255, 0)",
        "rgb(255,255,255)",
        "rgb(255,191,170)",
        "rgb(255,123,90)",
        "rgb(255,0,0)",
        "rgb(255,104,0)",
        "rgb(255,159,0)",
        "rgb(255,209,0)",
        "rgb(250,255,0)",
        "rgb(222,255,0)",
        "rgb(192,255,0)",
        "rgb(156,255,0)",
        "rgb(111,255,0)",
        "rgb(16,255,0)",
      ];
      this.heatmap.set("gradient", this.heatmap.get("gradient") ? null : gradient);
    },
    toggleRadius() {
      this.heatmap.set("radius", this.heatmap.get("radius") ? null : 20);
    },
    toggleOpacity() {
      this.heatmap.set("opacity", this.heatmap.get("opacity") ? null : 0.2);
    },
  },
  watch: {
    "$store.state.selectedCustomer.value"() {
      this.isLoading = false;
      this.hasData = false;
      this.startDate = "";
      this.endDate = "";
    }
  },
  mounted() {
    //this.initMap();
  },
  data() {
    return {
      title: "Impressões",
      map: null,
      heatmap: null,
      isLoading: false,
      hasData: false,
      startDate: "",
      endDate: "",
      selectedUF: "0",
      showClicks: false,
      showImpressions: true,
      showCoverageArea: false,
      centerPointsUF: {
        "1":{
          "latitude":-9.974757,
          "longitude":-67.8251498
        },
        "2":{
          "latitude":-9.6624762,
          "longitude":-35.7448409
        },
        "4":{
          "latitude":-3.1291223,
          "longitude":-60.0371526
        },
        "3":{
          "latitude":0.0379999,
          "longitude":-51.0668338
        },
        "5":{
          "latitude":-12.9780278,
          "longitude":-38.5229577
        },
        "6":{
          "latitude":-3.7272657,
          "longitude":-38.5359292
        },
        "7":{
          "latitude":-15.9055587,
          "longitude":-47.7801615
        },
        "8":{
          "latitude":-20.3202919,
          "longitude":-40.3466692
        },
        "9":{
          "latitude":-16.6669997,
          "longitude":-49.2609273
        },
        "10":{
          "latitude":-2.5298369,
          "longitude":-44.3078306
        },
        "13":{
          "latitude":-19.9196016,
          "longitude":-43.9484139
        },
        "12":{
          "latitude":-20.4601771,
          "longitude":-54.6208599
        },
        "11":{
          "latitude":-15.6526129,
          "longitude":-56.0596539
        },
        "14":{
          "latitude":-1.3410047,
          "longitude":-48.590471
        },
        "15":{
          "latitude":-7.1203122,
          "longitude":-34.8862428
        },
        "17":{
          "latitude":-8.0418098,
          "longitude":-35.0779382
        },
        "18":{
          "latitude":-5.0913164,
          "longitude":-42.8214221
        },
        "16":{
          "latitude":-25.4321587,
          "longitude":-49.2796458
        },
        "19":{
          "latitude":-22.9005256,
          "longitude":-43.1956282
        },
        "20":{
          "latitude":-5.7997439,
          "longitude":-35.2922854
        },
        "22":{
          "latitude":-8.763782,
          "longitude":-63.9095619
        },
        "23":{
          "latitude":2.8191594,
          "longitude":-60.6785815
        },
        "21":{
          "latitude":-30.029941,
          "longitude":-51.2395647
        },
        "24":{
          "latitude":-27.6016213,
          "longitude":-48.6307297
        },
        "26":{
          "latitude":-10.9115101,
          "longitude":-37.0583797
        },
        "25":{
          "latitude":-23.5428164,
          "longitude":-46.7029068
        },
        "27":{
          "latitude":-10.2596918,
          "longitude":-48.4873235
        }
      },
      UFs: [
        { label: "Brasil", value: "0" },
        { label: "AC", value: "1" },
        { label: "AL", value: "2" },
        { label: "AM", value: "4" },
        { label: "AP", value: "3" },
        { label: "BA", value: "5" },
        { label: "CE", value: "6" },
        { label: "DF", value: "7" },
        { label: "ES", value: "8" },
        { label: "GO", value: "9" },
        { label: "MA", value: "10" },
        { label: "MG", value: "13" },
        { label: "MS", value: "12" },
        { label: "MT", value: "11" },
        { label: "PA", value: "14" },
        { label: "PB", value: "15" },
        { label: "PE", value: "17" },
        { label: "PI", value: "18" },
        { label: "PR", value: "16" },
        { label: "RJ", value: "19" },
        { label: "RN", value: "20" },
        { label: "RO", value: "22" },
        { label: "RR", value: "23" },
        { label: "RS", value: "21" },
        { label: "SC", value: "24" },
        { label: "SE", value: "26" },
        { label: "SP", value: "25" },
        { label: "TO", value: "27" },
      ]
    };
  },
};
</script>
<style>
</style>
