<template>
  <div>
    <div v-show="isLoading">
      <p style="text-align: center">
        Por favor, aguarde! A busca pode demorar alguns minutos.
      </p>
      <div class="loader">
        <PacmanLoader :loading="isLoading" color="#d6611f" />
      </div>
    </div>
    <div v-show="!isLoading" class="card">
      <div>
        <nav-tabs :tabs="tabs">
          <template v-slot:tab0>
            <div v-show="!hasData" class="text-center">
              <h1 class="text-light mt-5">Sem apontamentos disponiveis</h1>
            </div>
            <div
              v-show="hasData & !isLoading"
              class="px-2"
            >
              <top-page :handleFilter="handleFilter" :tab="tabs[0]" />

              <table class="table table-responsive-xl scroll-dynad">
                <thead>
                  <tr>
                    <th>PLACEMENT</th>
                    <th>CAMPANHA</th>
                    <th>DATA DE CRIAÇÃO</th>
                    <th>DATA FIM PREVISTA CAMPANHA</th>
                    <th>INDICADOR DE APONTAMENTO</th>
                    <th>DATA DE SURGIMENTO</th>
                    <th class="text-center">GRAU</th>
                  </tr>
                </thead>
                <tbody style="background-color: #232323">
                  <tr v-for="apontamento in apontamentos" :key="apontamento.id">
                    <th style="color: rgba(255, 255, 255, 0.7)">
                      {{ apontamento.placement }}
                    </th>
                    <td>{{ apontamento.campaign_name }}</td>
                    <td>{{ apontamento.campaign_start_date }}</td>
                    <td>{{ apontamento.campaign_end_date }}</td>
                    <td>{{ apontamento.monitor_rule }}</td>
                    <td>{{ apontamento.date_created }}</td>
                    <td class="text-center">
                      <level
                        :level="apontamento.monitor_level"
                        :title="apontamento.monitor_level"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <template v-slot:tab1>
            <div v-show="!hasData" class="text-center">
              <h1 class="text-light mt-5">Sem apontamentos disponiveis</h1>
            </div>
            <div
              v-show="hasData & !isLoading"
              class="px-2"
            >
              <top-page :handleFilter="handleResolvedFilter" :tab="tabs[1]" />
              <table class="table table-responsive-xl scroll-dynad depth-page table-hover">
                <thead>
                  <tr>
                    <th>PLACEMENT</th>
                    <th>CAMPANHA</th>
                    <th>INDICADOR DE APONTAMENTO</th>
                    <th>DATA DE SURGIMENTO</th>
                    <th>DATA RESOLUÇÃO</th>
                    <th class="text-center">GRAU</th>
                  </tr>
                </thead>
                <tbody style="background-color: #232323" >
                  <tr
                    v-for="apontamento in apontamentosResolved"
                    :key="apontamento.id"
                  >
                    <th style="color: rgba(255, 255, 255, 0.7)">
                      {{ apontamento.placement }}
                    </th>
                    <td>{{ apontamento.campaign_name }}</td>
                    <td>{{ apontamento.monitor_rule }}</td>
                    <td>{{ apontamento.date_created }}</td>
                    <td>{{ apontamento.last_updated }}</td>
                    <td class="text-center">
                      <level
                        :level="apontamento.monitor_level"
                        :title="apontamento.monitor_level"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </nav-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import appointmentApi from "../plugins/api/monitor/appointement";
import NavTabs from "../components/NavTabs.vue";
import TopPage from "../components/Apontamentos/TopPage.vue";
import Level from "../components/Apontamentos/level.vue";
import { removeDiacrits } from "../Utils/string-utils";
import _ from "lodash";
import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";

export default {
  components: { NavTabs, TopPage, Level, PacmanLoader },
  data() {
    return {
      apontamentos: [],
      apontamentosResolved: [],
      filter: {},
      tabs: ["Apontamentos Atuais", "Resolvidos"],
      apontamentosFiltered: "",
      hasData: false,
      isLoading: false,
    };
  },
  watch: {
    "$store.state.selectedCustomer.value"() {
      this.getApontamentos(this.$store.state.selectedCustomer.value);
      this.getApontamentosResolved(this.$store.state.selectedCustomer.value);
    },
  },
  mounted() {
    this.getApontamentos(this.$store.state.selectedCustomer.value);
    this.getApontamentosResolved(this.$store.state.selectedCustomer.value);
  },
  methods: {
    handleFilter(filter) {
      this.apontamentos = _.filter(
        this.$store.state.apontamentos,
        (apontamento) => {
          for (const key in filter) {
            const strategy = this.filterStrategy(key);
            if (strategy) {
              if (!strategy(apontamento, filter, key)) return false;
            } else {
              const regex = new RegExp(
                removeDiacrits(filter[key].trim()),
                "gim"
              );
              if (!removeDiacrits(apontamento[key].trim()).match(regex))
                return false;
            }
          }
          return true;
        }
      );
    },
    filterStrategy(strategy) {
      const convertDates = (start, end, compare) => {
        const startDate = Date.parse(start);
        const endDate = Date.parse(end);
        const compareDate = Date.parse(compare);
        return { startDate, endDate, compareDate };
      };
      const commonRules = (translatedDates) => {
        if (translatedDates.startDate > translatedDates.compareDate)
          return false;
        if (translatedDates.endDate < translatedDates.compareDate) return false;

        return true;
      };
      const strategies = {
        dateRangeCreationDate: (apontamento, filter, key) => {
          const translatedDates = convertDates(
            filter[key].startDate,
            filter[key].endDate,
            apontamento.campaign_start_date
          );
          return commonRules(translatedDates);
        },
        dateRangeEndDate: (apontamento, filter, key) => {
          const translatedDates = convertDates(
            filter[key].startDate,
            filter[key].endDate,
            apontamento.campaign_end_date
          );
          return commonRules(translatedDates);
        },
        dateRangeAppearanceDate: (apontamento, filter, key) => {
          const translatedDates = convertDates(
            filter[key].startDate,
            filter[key].endDate,
            apontamento.date_created
          );
          return commonRules(translatedDates);
        },
      };
      return strategies[strategy];
    },
    handleResolvedFilter(filter) {
      this.apontamentosResolved = _.filter(
        this.$store.state.apontamentosResolved,
        (apontamento) => {
          for (const key in filter) {
            const strategy = this.filterStrategy(key);
            if (strategy) {
              if (!strategy(apontamento, filter, key)) return false;
            } else {
              const regex = new RegExp(
                removeDiacrits(filter[key].trim()),
                "gim"
              );
              if (!removeDiacrits(apontamento[key].trim()).match(regex))
                return false;
            }
          }
          return true;
        }
      );
    },
    async getApontamentos(customerId) {
      this.isLoading = true;
      this.hasData = false;
      const { data: apontamentos } = await appointmentApi.getUnsolved(
        customerId
      );
      if (Object.keys(apontamentos).length > 0) {
        this.hasData = true;
        this.isLoading = false;
        this.$store.commit("setApontamentos", apontamentos);
        this.apontamentos = apontamentos;
      } else {
        this.hasData = false;
        this.isLoading = false;
      }
    },
    async getApontamentosResolved(customerId) {
      this.isLoading = true;
      this.hasData = false;
      const { data: apontamentosResolved } = await appointmentApi.getSolved(
        customerId
      );
      if (Object.keys(apontamentosResolved).length > 0) {
        this.hasData = true;
        this.isLoading = false;

        this.$store.commit("setApontamentosResolved", apontamentosResolved);
        this.apontamentosResolved = apontamentosResolved;
      }
      else {
        this.hasData = false;
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
a {
  color: white;
}
a:active {
  color: white !important;
}

.success {
  background: var(--success-color) !important;
  color: black;
}
.success:hover {
  cursor: text !important;
  background: var(--success-color) !important;
  color: black;
}
.warning {
  background: var(--warning-color) !important;
  color: black;
}
.warning:hover {
  cursor: text !important;
  background: var(--warning-color) !important;
  color: black;
}
.error {
  background: var(--error-color) !important;
  color: black;
}
.error:hover {
  cursor: text !important;
  background: var(--error-color) !important;
  color: black;
}

.debug {
  border: 1px solid red;
}
#navbar {
  /* border-bottom: 1px solid #a5a4a1; */
  /* margin-left: 1px;
  margin-right: 1px; */
  background-color: #434343;
}
.depth-page {
  max-height: 50vh !important;
}
</style>
