<template>
  <card type="plain">
    <div class="col-12 text-right">
      <div class="form-row">
        <base-input class="col-md-3" label="Tipo para Análise">
          <select
            title="Selecione o Tipo para Busca..."
            @change="clearTable"
            id="domain"
            v-model="domain"
            class="form-control btn btn-secondary text-white dynad-select"
          >
            <option value="placement" default>Placement</option>
            <option value="campaign">Campaign</option>
            <option value="creative">Creative</option>
            <option value="native">Native</option>
          </select>
        </base-input>
        <base-input
          v-model="startDate"
          class="col-md-3"
          type="date"
          name="start_date"
          label="Data Inicial"
        />
        <base-input
          v-model="endDate"
          class="col-md-3"
          type="date"
          label="Data Final"
          name="end_date"
        />
        <div class="col-3 text-left">
          <div class="dynad-btn-side-option">
            <base-button type="primary" class="dynad-btn-report" @click="searchDeliveryPerformance">Buscar</base-button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isLoading">
      <p style="text-align: center">Por favor, aguarde! A busca pode demorar alguns minutos devido a Quantidade de Eventos para longos Intervalos de Datas.</p>
      <div class="loader">
        <PacmanLoader :loading="isLoading" color="#d6611f" />
      </div>
    </div>
    <div v-show="!isLoading & !hasData" class="text-center">
      <h1 class="text-light mt-5">Nenhum Evento Encontrado</h1>
    </div>
    <div v-show="!isLoading & hasData" class="col-12">
      <div class="row">
        <sorted-table :values="tableData">
          <thead>
          <tr>
            <th scope="col" style="width: 11%" class="dynad-table-sorter">
              <sort-link name="domain">{{ domain }}</sort-link>
            </th>
            <th scope="col" style="width: 56%" class="dynad-table-sorter">
              <sort-link name="name">Nome</sort-link>
            </th>
            <th scope="col" style="width: 11%" class="dynad-table-sorter">
              <sort-link name="impressions">Impressions</sort-link>
            </th>
            <th scope="col" style="width: 11%" class="dynad-table-sorter">
              <sort-link name="clicks">Clicks</sort-link>
            </th>
            <th scope="col" style="width: 11%" class="dynad-table-sorter">
              <sort-link name="ctr">CTR</sort-link>
            </th>
          </tr>
          </thead>
          <template #body="sort">
            <tbody>
            <tr v-for="value in sort.values" :key="value.id">
              <td>{{ value.domain }}</td>
              <td>{{ value.name }}</td>
              <td>{{ value.impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
              <td>{{ value.clicks.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
              <td>{{ parseFloat(value.ctr).toFixed(4).replace(".", ",") + "%" }}</td>
            </tr>
            </tbody>
          </template>
        </sorted-table>
      </div>
    </div>
  </card>
</template>
<script>
import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";
import moment from "moment";
export default {
  components: {
    PacmanLoader
  },
  methods: {
    async clearTable() {
      this.hasData = false;
      this.isLoading = false;
    },
    async searchDeliveryPerformance() {
      this.isLoading = true;
      let responseData = await this.getDeliveryPerformance();
      if ("domain" in responseData && "table" in responseData) {
        this.domain = responseData["domain"];
        this.tableData = responseData["table"];
        this.isLoading = false;
        this.hasData = true;
      } else {
        this.isLoading = false;
        this.hasData = false;
      }
    },
    async getDeliveryPerformance() {
      let customerId = this.$store.state.selectedCustomer.value;
      let domain = this.domain;
      let start_date = this.startDate;
      let end_date = this.endDate;
      if(domain && start_date && end_date) {
        const responseData = await this.getDeliveryPerformanceByDomain(customerId, domain, start_date, end_date);
        return responseData;
      }
      else {
        alert("Data Inicial e Data Final São Obrigatórios!")
        return {}
      }
    },
    async getDeliveryPerformanceByDomain(customerId, domain, start_date, end_date) {
      start_date = `${start_date} 00:00:00`;
      end_date = `${end_date} 23:59:59`;
      let params = {
        domain: domain,
        customer: customerId,
        start_date: start_date,
        end_date: end_date
      }
      const response = await this.$api.getDeliveryPerformanceByDomainAndCustomer(params);
      return response.data;
    },
  },
  watch: {
    "$store.state.selectedCustomer.value"() {
      this.isLoading = false;
      this.hasData = false;
      this.domain = "placement";
      this.tableData = [];
      this.startDate = "";
      this.endDate = "";
    }
  },
  mounted() {
    let today = moment();
    this.endDate = today.format("YYYY-MM-DD");
    this.startDate = this.endDate;
  },
  data() {
    return {
      isLoading: false,
      hasData: false,
      domain: "placement",
      tableData: [],
      startDate: "",
      endDate: ""
    };
  },
};
</script>
<style>
</style>
