<template>
  <div>
    <div v-show="errorMessage" class="loader">
      <h1 class="text-light">{{ errorMessage }}</h1>
    </div>
    <div v-show="!errorMessage">
      <h4>Preencha os Campos para criar um novo Report Customizado</h4>
      <card>
        <form @submit.prevent @submit="submitCustomReport">
          <base-input
            type="text"
            required
            v-model="reportDetails.name"
            label="Nome do Report"
          />
          <div class="form-row w-100">
            <base-input class="col-md-4" label="Tipo do Report">
              <select
                title="Selecione um Tipo do Report..."
                id="reportType"
                v-model="reportType"
                class="form-control btn btn-secondary text-white dynad-select"
              >
                <option value="Customizado" default>Customizado</option>
                <option value="Formulário">Formulário</option>
                <option value="FormulárioGerencial">Formulário Gerencial</option>
                <option value="Wishlist">Wishlist</option>
              </select>
            </base-input>
            <base-input class="col-md-4" label="Período do Report">
              <select
                title="Selecione um Tipo de Período..."
                id="reportPeriodType"
                v-model="reportPeriodType"
                class="form-control btn btn-secondary text-white dynad-select"
              >
                <option value="scheduled" default>Agendado</option>
                <option v-if="isWishlistReport === 'false'" value="byDate">Por Data</option>
                <option v-if="isWishlistReport === 'true'" value="byDate">Atual</option>
              </select>
            </base-input>
          </div>
          <div class="form-row w-100">

          </div>
          <div class="mb-3">
            <div
              v-if="reportPeriodType === 'scheduled'"
              class="form-row"
              width="100%"
            >
              <base-input
                class="col-md-4"
                required
                v-model="reportDetails.emails"
                label="Email"
                placeholder="Separe os e-mails por vírgula"
              />
              <base-input class="col-md-4" label="Dia do Agendamento">
                <select
                  id="scheduleDay"
                  v-model="reportDetails.selectedDay"
                  class="form-control btn btn-secondary text-white dynad-select"
                >
                  <option>Selecione o Dia...</option>
                  <option
                    v-for="option in scheduleDays"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </base-input>
              <base-input class="col-md-4" label="Hora do Agendamento">
                <select
                  id="scheduleHour"
                  v-model="reportDetails.selectedHour"
                  class="form-control btn btn-secondary text-white dynad-select"
                >
                  <option>Selecione a Hora...</option>
                  <option
                    v-for="option in scheduleHours"
                    :key="option.value"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </base-input>
            </div>
            <div v-else>
              <div v-if="isWishlistReport === 'false'" class="form-row">
                <base-input
                  v-model="reportDetails.start_date"
                  @change="validateHourRange"
                  @blur="validateHourRange"
                  class="col-md-4"
                  type="date"
                  name="start_date"
                  label="Data Inicial"
                />
                <base-input
                  v-model="reportDetails.end_date"
                  @change="validateHourRange"
                  @blur="validateHourRange"
                  class="col-md-4"
                  type="date"
                  label="Data final"
                  name="end_date"
                />
              </div>
            </div>
          </div>
          <div v-if="reportPeriodType === 'scheduled'">
            <p>Report Ativo?</p>
            <base-radio
              inline
              name="true"
              class="mb-3"
              v-model="radio.reportStatus"
              >Sim</base-radio
            >
            <base-radio
              inline
              name="false"
              class="mb-3"
              v-model="radio.reportStatus"
              >Não</base-radio
            >
          </div>
          <div v-show="isCustomReport === 'true'">
            <div>
              <p>Agrupamento</p>
              <base-radio :disabled="monthlyReport"
                inline
                name="Diário"
                class="mb-3"
                v-model="radio.grouping"
                >Diário</base-radio
              >
              <base-radio :disabled="monthlyReport"
                inline
                name="Horário"
                class="mb-3"
                v-model="radio.grouping"
                >Horário</base-radio
              >
              <base-radio
                inline
                name="Mensal"
                class="mb-3"
                v-model="radio.grouping"
              >Mensal</base-radio
              >
            </div>
            <div>
              <p>
                Utilizar as mesmas Dimensões e Métricas (Colunas) do Report
                Consolidado?
              </p>
              <base-radio
                inline
                name="consolidated"
                class="mb-3"
                v-model="radio.reportDimensionsType"
                >Sim</base-radio
              >
              <base-radio
                inline
                name="custom"
                class="mb-3"
                v-model="radio.reportDimensionsType"
                >Não</base-radio
              >
            </div>
            <p class="card-text">Dimensões</p>
            <div class="d-flex">
              <div class="dynad-dynamic-row">
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.campaign_name"
                  >Campaign</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.contract_name"
                  >Contract</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.cost_value"
                  >Cost</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.creative_name"
                  >Creative Name</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.cc_weight"
                  >Creative Weight</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.device_type"
                  >Device Type</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.cgf_list_filter"
                  >Filters</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.format_name"
                  >Format</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.label"
                  >Label</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.native_name"
                  >Native Ad</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.native_end_date"
                  >Native End Date</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.native_link"
                  >Native Link</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.native_start_date"
                  >Native Start Date</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.native_status"
                  >Native Status</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.native_type"
                  >Native Type</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.native_title"
                  >Native Title</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.sku"
                  :disabled="disableSku"
                  >Product SKU</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.product_category"
                  >Product Category</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.product_name"
                  >Product Name</base-checkbox
                ><base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.product_price"
                  >Product Price</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.placement_name"
                  >Placement</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.dimensions.site_name"
                  >Site Name</base-checkbox
                >
              </div>
            </div>
            <p>Métricas</p>
            <div class="d-flex">
              <div class="dynad-dynamic-row">
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.metrics.conversions_amount_last_click"
                  >Amount Sales Last Click</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.metrics.conversions_amount_post_click"
                  >Amount Sales Post Click</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.metrics.conversions_amount_post_view"
                  >Amount Sales Post View</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.metrics.clicks"
                  >Clicks</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.metrics.conversions_last_click"
                  >Conversions Sales Last Click</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.metrics.conversions_post_click"
                  >Conversions Sales Post Click</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.metrics.conversions_post_view"
                  >Conversions Sales Post View</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.percents.ctr"
                  >CTR</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.metrics.impressions"
                  >Impressions</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.percents.viewability_percent"
                  >Viewability Percent</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.percents.va_eligible_percent"
                  >Viewable Eligible Percent</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.metrics.va_eligible_print"
                  >Viewable Eligible Prints</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.percents.va_measurable_percent"
                  >Viewable Measurable Percent</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.metrics.va_measurable_print"
                  >Viewable Measurable Prints</base-checkbox
                >
                <base-checkbox
                  class="mb-3 dynad-flex-1"
                  v-model="checkboxes.metrics.va_print"
                  >Viewable Prints</base-checkbox
                >
              </div>
            </div>
          </div>
          <div class="text-right">
            <button type="submit" class="btn dynad-btn-report">
              {{
                reportPeriodType === "scheduled"
                  ? "Salvar Report"
                  : "Gerar Report"
              }}
            </button>
            <button type="submit" class="btn dynad-btn-report" @click="goToList">Cancelar</button>
          </div>
        </form>
      </card>
    </div>
  </div>
</template>
<script>
import { BaseButton, BaseRadio, BaseTable, BaseCheckbox } from "@/components";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import moment from "moment";

const DEFAULT_HOUR = "0";
const DEFAULT_DAY = "6";
const TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const TABLE_HREF = "/#/custom-report";

export default {
  components: {
    BaseButton,
    BaseRadio,
    BaseTable,
    ScaleLoader,
    BaseCheckbox,
  },
  methods: {
    goToList() {
      location.href = "/#/custom-report";
    },
    validateHourRange(event) {
      const { value, name } = event.target;
      const startDate = this.reportDetails.start_date;
      if (startDate) {
        const endDate = moment(this.reportDetails.end_date).subtract(
          62,
          "days"
        );
        if (moment(startDate).isBefore(endDate)) {
          this.monthlyReport = true;
          this.radio.grouping = "Mensal";
        } else {
          this.monthlyReport = false;
          this.radio.grouping = "Diário";
        }
      }
    },
    goToTable() {
      location.href = TABLE_HREF;
    },
    getTimeNow() {
      return moment().format(TIME_FORMAT);
    },
    async submitCustomReport() {
      const payloadArray = [];
      const payload = {
        id: this.id,
        customer: this.$store.state.selectedCustomer.value,
        emails: this.reportDetails.emails,
        report_name: this.reportDetails.name,
        fields: JSON.stringify(this.getCheckboxesValuesAsJson()),
        report_grouping: this.radio.grouping,
        report_type: this.reportType,
        user_created: this.userCreated,
        user_updated: this.userUpdated,
        date_created: this.getTimeNow(),
        last_updated: this.getTimeNow()
      };
      if (typeof this.radio.reportStatus === "string") {
        payload.status = (this.radio.reportStatus === "true");
      } else {
        payload.status = this.radio.reportStatus;
      }
      if (this.reportPeriodType === "scheduled") {
        payload.schedule_day = this.reportDetails.selectedDay;
        payload.schedule_hour = this.reportDetails.selectedHour;
        payloadArray.push(payload);
        await this.$api.createNewCustomReport(payloadArray);
      }
      if (this.reportPeriodType === "byDate") {
        if (this.isWishlistReport === "true") {
          this.reportDetails.start_date = "2020-01-01";
          this.reportDetails.end_date = "2030-01-01";
        }
        if (!this.reportDetails.start_date || !this.reportDetails.end_date) {
          alert("Intervalo de Data Inválido");
          return;
        }
        payload["start_date"] = moment(this.reportDetails.start_date).format(
          TIME_FORMAT
        );
        payload["end_date"] = moment(
          `${this.reportDetails.end_date} 23:59:59`
        ).format(TIME_FORMAT);
        await this.$api.createNewCustomReportByDateRange(payload);
      }
      this.goToTable();
    },
    getCheckboxesValuesAsJson() {
      const checkboxes = this.checkboxes;
      const checkboxesJson = {};
      for (let fieldName in checkboxes) {
        checkboxesJson[fieldName] = checkboxes[fieldName];
      }
      return checkboxesJson;
    },
    async getCustomReportById(reportID, reportType) {
      let params = {
        id: reportID,
        type: reportType
      };
      const response = await this.$api.getCustomReportById(params);
      return response.data;
    },
  },
  data() {
    return {
      fields: "",
      id: "",
      userCreated: "",
      userUpdated: "",
      reportPeriodType: "scheduled",
      reportType: "Customizado",
      isCustomReport: "true",
      isWishlistReport: "false",
      monthlyReport: false,
      reportDetails: {
        name: "",
        emails: "",
        selectedHour: DEFAULT_HOUR,
        selectedDay: DEFAULT_DAY,
        end_date: "",
        start_date: "",
      },
      interval: null,
      errorMessage: null,
      checkboxes: {
        dimensions: {
          bot: false,
          browser: false,
          browser_version: false,
          campaign_name: false,
          contract_name: false,
          cost_value: false,
          creative_name: false,
          cc_weight: false,
          device_type: false,
          event_reason: false,
          cgf_list_filter: false,
          format_name: false,
          label: false,
          native_name: false,
          native_end_date: false,
          native_link: false,
          native_title: false,
          native_start_date: false,
          native_status: false,
          native_type: false,
          sku: false,
          os: false,
          os_version: false,
          placement_name: false,
          site_name: false,
          valid_event: false,
          product_category: false,
          product_price: false,
          product_name: false
        },
        metrics: {
          conversions_amount_last_click: false,
          conversions_amount_post_click: false,
          conversions_amount_post_view: false,
          clicks: false,
          conversions_last_click: false,
          conversions_post_click: false,
          conversions_post_view: false,
          impressions: false,
          va_eligible_print: false,
          va_measurable_print: false,
          va_print: false,
        },
        percents: {
          ctr: false,
          viewability_percent: false,
          va_eligible_percent: false,
          va_measurable_percent: false,
        },
      },
      radio: {
        grouping: "Diário",
        reportDimensionsType: "custom",
        reportStatus: true
      },
      scheduleDays: [
        { label: "Segunda-Feira", value: "1" },
        { label: "Terça-Feira", value: "2" },
        { label: "Quarta-Feira", value: "3" },
        { label: "Quinta-Feira", value: "4" },
        { label: "Sexta-Feira", value: "5" },
        { label: "Sábado", value: "6" },
        { label: "Domingo", value: "7" },
        { label: "Todos os Dias", value: "*" },
      ],
      scheduleHours: [
        { label: "00", value: "0" },
        { label: "01", value: "1" },
        { label: "02", value: "2" },
        { label: "03", value: "3" },
        { label: "04", value: "4" },
        { label: "05", value: "5" },
        { label: "06", value: "6" },
        { label: "07", value: "7" },
        { label: "08", value: "8" },
        { label: "09", value: "9" },
        { label: "10", value: "10" },
        { label: "11", value: "11" },
        { label: "12", value: "12" },
        { label: "13", value: "13" },
        { label: "14", value: "14" },
        { label: "15", value: "15" },
        { label: "16", value: "16" },
        { label: "17", value: "17" },
        { label: "18", value: "18" },
        { label: "19", value: "19" },
        { label: "20", value: "20" },
        { label: "21", value: "21" },
        { label: "22", value: "22" },
        { label: "23", value: "23" },
        { label: "Todas as Horas", value: "*" },
      ],
    };
  },
  computed:{
    disableSku(){
       return this.checkboxes.dimensions.product_category
      || this.checkboxes.dimensions.product_name
      || this.checkboxes.dimensions.product_price 
    }
  },
  created() {
    this.radio.reportDimensionsType = "consolidated";
    this.isCustomReport = "true";
    this.userCreated = localStorage.getItem("user");
    this.userUpdated = localStorage.getItem("user");
  },
  watch: {
    $route: {
      immediate: true,
      handler: async function (newRoute) {
        try {
          let customReportID = newRoute.query.id;
          let customReportType = newRoute.query.type;
          if (customReportID && customReportType) {
            let reportData = await this.getCustomReportById(customReportID, customReportType);
            reportData = reportData[0];
            if (reportData) {
              this.id = reportData.id;
              this.reportDetails.name = reportData.report_name;
              this.reportDetails.emails = reportData.emails;
              this.userCreated = reportData.user_created;
              this.userUpdated = localStorage.getItem("user");
              this.reportType = reportData.report_type;
              this.radio.reportStatus = reportData.status != 0;
              this.radio.grouping = reportData.report_grouping;
              this.radio.reportDimensionsType = "custom_report";
              this.fields = reportData.fields;

              if (reportData.report_type === "Customizado") {
                this.isCustomReport = "true";
                this.isWishlistReport = "false";
              } else if (reportData.report_type === "Formulário") {
                this.isCustomReport = "false";
                this.isWishlistReport = "false";
              } else if (reportData.report_type === "FormulárioGerencial") {
                this.isCustomReport = "false";
                this.isWishlistReport = "false";
              } else if (reportData.report_type === "Wishlist") {
                this.isCustomReport = "false";
                this.isWishlistReport = "true";
              }

              if (customReportType == "scheduled") {
                this.reportPeriodType = "scheduled";
                this.reportDetails.selectedDay = reportData.schedule_day;
                this.reportDetails.selectedHour = reportData.schedule_hour;
              } else if (customReportType == "byDate") {
                this.reportPeriodType = "byDate";
                this.reportDetails.start_date = moment.unix(reportData.start_date / 1000).format("YYYY-MM-DD");
                this.reportDetails.end_date = moment.unix(reportData.end_date / 1000).format("YYYY-MM-DD");
              }
            }
          }
        } catch (error) {
          console.error(error);
        }
      },
    },
    "reportType"(type) {
      this.reportPeriodType = "scheduled";
      if (type === "Customizado") {
        this.isCustomReport = "true";
        this.isWishlistReport = "false";
      } else if (type === "Formulário") {
        this.isCustomReport = "false";
        this.isWishlistReport = "false";
      } else if (type === "FormulárioGerencial") {
        this.isCustomReport = "false";
        this.isWishlistReport = "false";
      } else if (type === "Wishlist") {
        this.isCustomReport = "false";
        this.isWishlistReport = "true";
      }
    },
    "radio.reportDimensionsType"(type) {
      if (type === "consolidated") {
        this.checkboxes = {
          dimensions: {
            campaign_name: true,
            contract_name: true,
            cost_value: true,
            creative_name: true,
            cc_weight: true,
            cgf_list_filter: true,
            format_name: true,
            label: true,
            native_name: true,
            native_title: true,
            placement_name: true,
            site_name: true,
          },
          metrics: {
            conversions_amount_last_click: true,
            conversions_amount_post_click: true,
            conversions_amount_post_view: true,
            clicks: true,
            conversions_last_click: true,
            conversions_post_click: true,
            conversions_post_view: true,
            impressions: true,
            va_eligible_print: true,
            va_measurable_print: true,
            va_print: true,
          },
          percents: {
            ctr: true,
            viewability_percent: true,
            va_eligible_percent: true,
            va_measurable_percent: true,
          },
        };
      } else if (type === "custom") {
        this.checkboxes = {
          dimensions: {
            campaign_name: false,
            contract_name: false,
            cost_value: false,
            creative_name: false,
            cc_weight: false,
            cgf_list_filter: false,
            format_name: false,
            label: false,
            native_name: false,
            native_title: false,
            placement_name: false,
            site_name: false,
          },
          metrics: {
            conversions_amount_last_click: false,
            conversions_amount_post_click: false,
            conversions_amount_post_view: false,
            clicks: false,
            conversions_last_click: false,
            conversions_post_click: false,
            conversions_post_view: false,
            impressions: false,
            va_eligible_print: false,
            va_measurable_print: false,
            va_print: false,
          },
          percents: {
            ctr: false,
            viewability_percent: false,
            va_eligible_percent: false,
            va_measurable_percent: false,
          },
        };
      } else if (type === "custom_report") {
        let jsonFields = JSON.parse(this.fields);
        this.checkboxes = {
          dimensions: jsonFields.dimensions,
          metrics: jsonFields.metrics,
          percents: jsonFields.percents
        }
      }
    },
    "disableSku"(value){
      this.checkboxes.dimensions.sku = value
    }
  },
  beforeDestroy() {},
};
</script>

<style>
.dynad-dynamic-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.dynad-flex-1 {
  flex: 1;
  min-width: 220px;
  max-width: 220px;
}
</style>
