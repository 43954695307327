<template>
  <div>
    <div v-show="isLoading" class="loader">
      <PacmanLoader :loading="isLoading" color="#d6611f" />
    </div>
    <div v-show="errorMessage" class="loader">
      <h1 class="text-light">{{ errorMessage }}</h1>
    </div>
    <div v-show="!errorMessage">
      <div v-show="!hasData & !isLoading" class="row">
        <div class="col-md-12">
          <base-alert type="success">
            <span>Parabéns! Suas Campanhas estão Veiculando sem nenhum Ponto De Atenção!</span>
          </base-alert>
        </div>
      </div>
      <div v-show="hasData & !isLoading" class="row">
        <div class="col-md-12">
          <base-alert type="warning">
            <span>Atenção: Algumas Campanhas Ativas podem estar com Problemas!</span>
          </base-alert>
        </div>
        <div class="col-md-12">
          <h4>Última Atualização: {{lastUpdated}}</h4>
        </div>
        <div v-show="hasZeroImpressionsData" class="col-md-12">
          <h4>Campanhas Ativas com 0 (Zero) Impressões</h4>
          <base-table :data="zeroImpressionsData" :columns="tableColumns">
            <template slot="columns">
              <th>ID</th>
              <th>Campaign</th>
              <th>Impressões</th>
            </template>
            <template slot-scope="{ row }">
              <td>{{ row.campaign }}</td>
              <td>{{ row.campaign_name }}</td>
              <td>{{ row.impressions }}</td>
            </template>
          </base-table>
        </div>
        <div v-show="hasHighCTRData" class="col-md-12">
          <h4>Campanhas Ativas com Possível CTR Alto</h4>
          <base-table :data="highCTRData" :columns="tableColumns">
            <template slot="columns">
              <th>ID</th>
              <th>Campaign</th>
              <th>CTR</th>
            </template>
            <template slot-scope="{ row }">
              <td>{{ row.campaign }}</td>
              <td>{{ row.campaign_name }}</td>
              <td>{{ parseFloat(row.ctr).toFixed(2) + "%" }}</td>
            </template>
          </base-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {BaseAlert, BaseTable} from "@/components";
import moment from "moment";
import PacmanLoader from "vue-spinner/src/PacmanLoader";

export default {
  components: {
    BaseAlert,
    BaseTable,
    PacmanLoader
  },
  methods: {
    async getMonitorAlerts() {
      this.isLoading = true;
      let customerId = this.$store.state.selectedCustomer.value;
      if (customerId){
        const monitorAlerts = await this.getMonitorAlertsByCustomer(customerId);
        if (monitorAlerts) {
          this.lastUpdated = moment(monitorAlerts["last_update"]).format("DD/MM/YYYY HH:mm:ss")
          this.hasData = true;
          let zeroImpressionsData = monitorAlerts["campaigns_zero_impressions"];;
          if (zeroImpressionsData && zeroImpressionsData.length > 0) {
            this.zeroImpressionsData = zeroImpressionsData;
            this.hasZeroImpressionsData = true;
          } else {
            this.zeroImpressionsData = [];
            this.hasZeroImpressionsData = false;
          }
          let highCTRData = monitorAlerts["campaigns_high_ctr"];
          if (highCTRData && highCTRData.length > 0) {
            this.highCTRData = highCTRData;
            this.hasHighCTRData = true;
          } else {
            this.highCTRData = [];
            this.hasHighCTRData = false;
          }
          if (zeroImpressionsData && zeroImpressionsData.length == 0 && highCTRData && highCTRData.length == 0) {
            this.lastUpdated = "";
            this.hasData = false;
            this.hasZeroImpressionsData = false;
            this.hasHighCTRData = false;
          }
        } else {
          this.lastUpdated = "";
          this.hasData = false;
        }
        this.isLoading = false;
      }
    },
    async getMonitorAlertsByCustomer(customerId) {
      let params = {
        customer : customerId
      }
      const response = await this.$api.getMonitorAlertsByCustomer(params);
      return response.data;
    },
  },
  data() {
    return {
      isLoading: true,
      hasData: false,
      lastUpdated: "",
      tableColumns: [],
      zeroImpressionsData: [],
      hasZeroImpressionsData: false,
      highCTRData: [],
      hasHighCTRData: false,
      errorMessage: null
    };
  },
  created() {
    this.getMonitorAlerts();
  },
  watch: {
    "$store.state.selectedCustomer.value"() {
      this.getMonitorAlerts();
    }
  },
};
</script>
<style>
</style>
