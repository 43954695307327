import axios from "axios";

const api = axios.create({
  baseURL: "https://datalake-report.dynad.net/analytics",
  headers: {
    Authorization: getLocalToken()
  }
});

export default {
  getUnsolved: customerId => {
    return api.get(`/monitor/alarms?customer=${customerId}&status=true`);
  },
  getSolved: customerId => {
    return api.get(`/monitor/alarms?customer=${customerId}&status=false`);
  }
};

function getToken() {
  const query = window.location.search;
  const token = querystring.parse(query.split("?")[1]).t;
  if (token) {
    localStorage.setItem("__tk__da", token);
  }
  return `Bearer ${token}`;
}

function getLocalToken() {
  const localToken = localStorage.getItem("__tk__da");
  if (localToken) {
    return `Bearer ${localToken}`;
  } else {
    return getToken();
  }
}
