<template>
  <div>
    <div v-show="errorMessage" class="loader">
      <h1 class="text-light">{{ errorMessage }}</h1>
    </div>
    <div v-show="!errorMessage">
      <h4>Selecione o Relatório Consolidado para Download</h4>
      <div class="row">
        <base-table :data="tableData" :columns="columns">
          <template slot="columns">
            <th>Data</th>
            <th>Download</th>
            <th>Status</th>
          </template>
          <template slot-scope="{ row }">
            <td>{{ row.date }}</td>
            <td class="td-actions">
              <base-button v-if="row.showButton" size="sm" @click="downloadReport(row)" icon>
                <i class="tim-icons icon-cloud-download-93"></i>
              </base-button>
              <div v-if="!row.showButton">
                <ScaleLoader :loading=true color="#d6611f" height="20px" width="2px" class="text-left" />
              </div>
            </td>
            <td>{{ row.status }}</td>
          </template>
        </base-table>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseTable } from "@/components";
import moment from "moment";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  components: {
    BaseTable,
    ScaleLoader,
  },
  methods: {
    changeRow(index, changes) {
      this.tableData[index].status = changes.status;
      this.tableData[index].showButton = changes.showButton;
    },
    async downloadReport(row) {
      this.changeRow(row.index, {status: "Processing", showButton: false});
      const date = row.date;
      let customerId = this.$store.state.selectedCustomer.value;
      let customerName = this.$store.state.selectedCustomer.text;
      const params = {
        customer: customerId,
        date_from: `${date}-01`,
      };
      const response = await this.$api.downloadReport(params);
      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        const link = document.createElement("a");
        link.setAttribute("download", `${customerName}-${date}`);
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
        this.changeRow(row.index, {status: "Completed", showButton: true});
      }
      if (response.status > 303) {
        this.errorMessage = "Erro ao buscar os dados!";
      }
    },
    async getTableReportDates() {
        let customerId = this.$store.state.selectedCustomer.value;
        if(customerId){
          const reportStatusResponse = await this.getTableReportStatus(customerId);

          let awsDataLakeStart = moment("2020/07/01", "YYYY/MM/DD");
          let today = moment();
          let tableData = [];
          let index = 0;
          while (today.isAfter(awsDataLakeStart)) {
            let reportStatus = reportStatusResponse[today.format("YYYYMM")]?.status;
            let showButton;
            if (reportStatus === "Completed") {
              showButton = true;
            } else if (reportStatus === "Processing") {
              showButton = false;
            } else {
              showButton = true;
            }
            let tableItem = {
              index: index,
              date: today.format("YYYY-MM"),
              status: reportStatus,
              showButton: showButton
            };
            tableData.push(tableItem);
            index++;
            today = today.subtract(1, "months");
          }
          this.tableData = tableData;
        }
    },
    async getTableReportStatus(customerId) {
      let params = {
        customer : customerId
      }
      const response = await this.$api.getReportStatus(params);
      return response.data;
    },
  },
  data() {
    return {
      columns: ["date", "status"],
      tableData: [],
      interval: null,
      errorMessage: null,
    };
  },
  created() {
    this.getTableReportDates();
    this.timer = setInterval(this.getTableReportDates, 30000);
  },
  watch: {
    "$store.state.selectedCustomer.value"() {
      this.getTableReportDates();
    }
  },
  beforeDestroy () {
      clearInterval(this.timer);
  }
};
</script>

<style>
</style>
