<template>
  <div v-bind:class="type" class="d-flex justify-content-center text-uppercase">
    <strong>{{ title }}</strong>
  </div>
</template>

<script>
export default {
  name: "level",
  props: {
    level: {
      type: String,
      required: true,
      default: "Leve",
      validator: function (value) {
        return ["Leve", "Importante", "Grave"].indexOf(value) !== -1;
      },
    },
    additionalClasses: [String],
    title: String,
  },
  data() {
    return {
      type: `${this.level.toLowerCase()}-image`,
    };
  },
};
</script>

<style>
.leve-image {
  width: 110px;
  height: 30px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: var(--success-color);
  color: black;
}
.importante-image {
  width: 110px;
  height: 30px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: var(--warning-color);
  color: black;
}
.grave-image {
  width: 110px;
  height: 30px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: var(--error-color);
  color: black;
}
</style>
